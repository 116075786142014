export default class PessoaNaturezaFiscalEnum {
    static VAZIO = { cod: null, description: '' };
    static NATURAL_PERSON = { cod: 'NATURAL_PERSON', description: 'Pessoa física' };
    static JURIDICAL_PERSON = { cod: 'JURIDICAL_PERSON', description: 'Pessoa jurídica' };
  
    static values() {
      return [
        PessoaNaturezaFiscalEnum.VAZIO,
        PessoaNaturezaFiscalEnum.NATURAL_PERSON,
        PessoaNaturezaFiscalEnum.JURIDICAL_PERSON,
      ];
    }
  }

