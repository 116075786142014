import ComboActions from '@/pages/combo/enums/comboActions'

export const comboRouteCreateEnum =
{
  id: 'combo',
  path: "/combo/novo",
  name: "combo-novo",
  description: "Novo combo",
  order: -1,
  component: () => import("@/pages/combo/components/ComboForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ComboActions.CREATE_COMBO ]
  }
}
