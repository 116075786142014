export default class PaymentTypeEnum {
  static VAZIO = { cod: null, description: "" };
  static CREDIT_CARD = { cod: "CREDIT_CARD", description: "Cartão de Crédito" };
  static DEBIT_CARD = { cod: "DEBIT_CARD", description: "Cartão de Débito" };
  static CASH = { cod: "CASH", description: "Dinheiro" };
  static PIX = { cod: "PIX", description: "Pix" };
  static BANK_SLIP = { cod: "BANK_SLIP", description: "Boleto" };
  static CREDIT = { cod: "CREDIT", description: "Crédito" };

  static values() {
    return [
      PaymentTypeEnum.VAZIO,
      PaymentTypeEnum.CREDIT_CARD,
      PaymentTypeEnum.DEBIT_CARD,
      PaymentTypeEnum.CASH,
      PaymentTypeEnum.PIX,
      PaymentTypeEnum.BANK_SLIP,
      PaymentTypeEnum.CREDIT,
    ];
  }
}
