<template>
  <v-container fluid>
    <CardList
      :name="'Pedidos'"
      :headers="headers"
      :items="listObjects"
      :loading="orderStore.getIsLoading"
      :menu="getMenu()"
      :refresh="false"
      @action="(items, aciton) => handleAction(items, aciton)"
    />
  </v-container>
</template>

<script>
import CardList from "@/components/CardList.vue";
import PaymentStatusEnum from "@/pages/payment/enums/PaymentStatusEnum";
import FormPaymentEnum from "@/pages/payment/enums/FormPaymentEnum";
import PaymentTypeEnum from "@/pages/payment/enums/PaymentTypeEnum";
import StatusPedidoEnum from "@/pages/order/enums/StatusPedidoEnum";

import { useOrderStore } from "@/pages/order/usecases/index";
import { formatDate } from "@/utils/dateUtil";
import Formatter from "@/utils/formatter";

export default {
  name: "OrdersPage",
  props: {
    listObjects: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    CardList,
  },
  setup() {
    const orderStore = useOrderStore();
    return {
      orderStore,
    };
  },
  data() {
    return {
      headers: [
        { text: "Numero", align: "start", value: "id" },
        { text: "Criação", value: "instante", formatter: this.formatDate },
        {
          text: "Status",
          value: "status",
          formatter: this.formatStatusPedido,
          layout: { chip: { permissao: true, acao: this.formataStatusChip } },
        },
        {
          text: "Endereço de entrega",
          value: "deliveryAddressFormatted",
        },
        {
          text: "Parcelas",
          value: "quantityInstallments",
          layout: { chip: { permissao: true } },
        },
        { text: "Vencimento", value: "dueDate", formatter: this.formatDate },
        {
          text: "Saldo devedor",
          value: "totalDebt",
          formatter: Formatter.formatCurrency,
        },
        {
          text: "Valor do pedido",
          value: "total",
          formatter: Formatter.formatCurrency,
        },
        {
          text: "Desconto",
          value: "discount",
          formatter: Formatter.formatCurrency,
        },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    formataStatusChip(value) {
      if (value === StatusPedidoEnum.ENTREGUE.cod) return "primary";
      if (value === StatusPedidoEnum.NA_ROTA.cod) return "gray";
      if (value === StatusPedidoEnum.CANCELADO.cod) return "error";
    },
    formatDate(date) {
      return formatDate(date);
    },
    formatStatusPedido(value) {
      const statusEnum = StatusPedidoEnum.values().find(
        (status) => status.cod === value
      );
      return statusEnum ? statusEnum.description : value;
    },
    formatPaymentStatus(value) {
      const statusEnum = PaymentStatusEnum.values().find(
        (status) => status.cod === value
      );
      return statusEnum ? statusEnum.description : value;
    },
    formatFormPayment(value) {
      const statusEnum = FormPaymentEnum.values().find(
        (status) => status.cod === value
      );
      return statusEnum ? statusEnum.description : value;
    },
    formatPaymentType(value) {
      const statusEnum = PaymentTypeEnum.values().find(
        (status) => status.cod === value
      );
      return statusEnum ? statusEnum.description : value;
    },
    handleAction(items, action) {
      const listener = this.orderStore.getActionsListener();
      const actionFinded = listener.find((a) => a.action === action);
      if (!actionFinded) return;
      actionFinded.execute(items);
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: this.orderStore.getMenuActions(),
      };
    },
  },
};
</script>
