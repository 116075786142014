import StockActions from '@/pages/stock/enums/stockActions'

export const stockRouteCreateEnum =
{
  id: 'stock',
  path: "/estoque/novo",
  name: "stock-novo",
  description: "Novo estoque",
  order: -1,
  component: () => import("@/pages/stock/components/StockForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ StockActions.CREATE_STOCK ]
  }
}
