import { defineStore } from "pinia";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { useContactStore } from "@/pages/contact/usecases/index";
import { useOrderStore } from "@/pages/order/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { useAddressStore } from "@/pages/address/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { customerRoutes } from "@/pages/customer/router/index";
import CustomerActions from "@/pages/customer/enums/CustomerActions";
import ImageForm from "@/pages/customer/components/ImageForm.vue";

import * as createAction from "@/pages/customer/usecases/createAction.js";
import * as findPaginateAction from "@/pages/customer/usecases/findPaginateAction.js";
import * as findAllAction from "@/pages/customer/usecases/findAllAction.js";
import * as findByIdAction from "@/pages/customer/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/customer/usecases/updateByIdAction.js";
import * as deleteByIdAction from "@/pages/customer/usecases/deleteByIdAction.js";
import * as deleteAllByIdAction from "@/pages/customer/usecases/deleteAllByIdAction.js";
import * as searchAction from "@/pages/customer/usecases/searchAction.js";

export const useCustomerStore = defineStore("customerStore", {
  state: () => ({
    formData: {
      name: null,
      email: null,
      cpfOuCnpj: null,
      type: null,
      address: [],
      contacts: [],
      orders: [],
      files: [],
      status: null,
    },
    list: [],
    listLoaded: false,
    isLoading: false,
    closeModal: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create() {
      await createAction.create(this);
    },

    async findPaginate(pagination, force) {
      await findPaginateAction.findPaginate(this, pagination, force);
    },

    async findAll() {
      return await findAllAction.findAll(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById() {
      await updateByIdAction.updateById(this);
    },

    async deleteById(field, fields) {
      await deleteByIdAction.deleteById(this, field, fields);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    async search(fields) {
      await searchAction.search(this, fields);
    },

    getCustomer(object) {
      return {
        id: object.id,
        name: object.person?.name,
        email: object.person?.email,
        cpfOuCnpj: object.person?.cpfOuCnpj,
        type: object.person?.type,
        files: object.person?.files,
        status: object.person?.status,
        personId: object.persn?.id,
        orders: object.orders,
        contacts: object.person?.contacts,
        address: object?.person?.address?.map((element) => {
          element.cityId = element.city?.id;
          element.regionId = element.region?.id;
          return element;
        }),
      };
    },

    async refresh() {
      await this.findPaginate({ page: this.getCurrentPage }, true);
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(CustomerActions.FIND_ALL_CUSTOMER);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    paginateValidation(force, pagination) {
      return !force && pagination.page === this.currentPage && this.listLoaded;
    },

    addContact(contact) {
      return this.formData.contacts.push(contact);
    },

    addAddress(contact) {
      return this.formData.address.push(contact);
    },

    setType(type) {
      return this.formData.type = type;
    },

    cleanData() {
      this.formData.name = null;
      this.formData.email = null;
      this.formData.cpfOuCnpj = null;
      this.formData.type = null;
      this.formData.address = [];
      this.formData.contacts = [];
      this.formData.orders = [];
      this.formData.files = [];
      this.formData.status = null;
      useContactStore().cleanData();
      useContactStore().resetIds();
      useAddressStore().cleanData();
      useAddressStore().resetIds();
      useOrderStore().cleanData();
      useOrderStore().resetIds();
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(CustomerActions.CREATE_CUSTOMER),
        new ValidateActionHandler(),
        () => {
          this.cleanData();
          router.push(
            router.getPathFromRouteName(
              customerRoutes.customerRouteCreateEnum.name
            ),
            null,
            () => {}
          );
        }
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(CustomerActions.FIND_ALL_CUSTOMER),
        new ValidateEditHandler(),
        (items) =>
          router.push(
            router.getPathFromRouteName(
              customerRoutes.customerRouteSearchEnum.name
            ) +
              "/" +
              items[0].id,
            null,
            () => {}
          )
      );
    },

    actionDisable() {
      return new Action(
        ActionEnum.DISABLE.name,
        ActionEnum.DISABLE.icon,
        ActionEnum.DISABLE.action,
        this.hasPermission(CustomerActions.DISABLE_CUSTOMER),
        new ValidateDeleteHandler(),
        (items) => this.deleteAllById(items, this.getList)
      );
    },

    actionFilter() {
      return new Action(
        ActionEnum.FILTER.name,
        ActionEnum.FILTER.icon,
        ActionEnum.FILTER.action,
        this.hasPermission(CustomerActions.SEARCH_CUSTOMER),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              customerRoutes.customerRouteSearchParametersEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(CustomerActions.FIND_ALL_CUSTOMER),
        new ValidateActionHandler(),
        () => this.refresh()
      );
    },

    actionPaginate() {
      return new Action(
        ActionEnum.PAGINATE.name,
        ActionEnum.PAGINATE.icon,
        ActionEnum.PAGINATE.action,
        this.hasPermission(CustomerActions.FIND_ALL_CUSTOMER),
        new ValidateActionHandler(),
        (pagination) => this.findPaginate(pagination)
      );
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(CustomerActions.CREATE_CUSTOMER),
        new ValidateActionHandler(),
        (items) => this.create()
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(CustomerActions.UPDATE_CUSTOMER),
        new ValidateActionHandler(),
        () => this.updateById()
      );
    },

    actionSearch() {
      return new Action(
        ActionEnum.SEARCH.name,
        ActionEnum.SEARCH.icon,
        ActionEnum.SEARCH.action,
        this.hasPermission(CustomerActions.SEARCH_CUSTOMER),
        new ValidateActionHandler(),
        (items) => this.search(items)
      );
    },

    actionOpenImage() {
      return new Action(
        "Abrir galeria de imagens",
        "mdi-image",
        "openImage",
        true,
        new ValidateActionHandler(),
        (items) => {
          useDialogStore().openDialogWithData({
            title: "Galeria de imagens",
            childComponent: ImageForm,
            data: items,
          });
        }
      );
    },

    getMenuFormActions() {
      return [this.actionOpenImage()];
    },

    getMenuActions() {
      return [
        this.actionCreate(),
        this.actionEdit(),
        this.actionDisable(),
        this.actionFilter(),
      ];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        ...this.getMenuFormActions(),
        this.actionRefresh(),
        this.actionPaginate(),
        this.actionCreateSave(),
        this.actionEditSave(),
        this.actionSearch(),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getCloseModal() {
      return this.$state.closeModal;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findPaginate({ page: this.$state.currentPage });
      }
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
