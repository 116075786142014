import VueRouter from "vue-router";
import { useAuthStore } from "@/pages/auth/usecases/index";

import { comboRoutes } from "@/pages/combo/router/index";
import { parameterRoutes } from "@/pages/parameter/router/index";
import { stockRoutes } from "@/pages/stock/router/index";
import { userRoutes } from "@/pages/user/router/index";
import { productRoutes } from "@/pages/product/router/index";
import { regionRoutes } from "@/pages/region/router/index";
import { workOrderRoutes } from "@/pages/workOrder/router/index";
import { contactRoutes } from "@/pages/contact/router/index";
import { addressRoutes } from "@/pages/address/router/index";
import { orderRoutes } from "@/pages/order/router/index";
import { customerRoutes } from "@/pages/customer/router/index";
import { reportRoutes } from "@/pages/report/router/index";
import { roleRoutes } from "@/pages/role/router/index";
import { permissionRoutes } from "@/pages/permission/router/index";
import { billingRoutes } from "@/pages/billingRoute/router/index";
import { rotaDeEntregaRotas } from "@/pages/rotaDeEntrega/router/index";
import { painelRoutes } from "@/pages/painel/router/index";

import { authRoutes } from "@/pages/auth/router/index";

const routes = [
  {
    path: "*",
    name: "ErrorView",
    component: () => import("@/pages/ErrorPage.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/pages/home/HomePage.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  ...Object.values(addressRoutes),
  ...authRoutes,
  ...Object.values(billingRoutes),
  ...Object.values(rotaDeEntregaRotas),
  ...Object.values(comboRoutes),
  ...Object.values(contactRoutes),
  ...Object.values(customerRoutes),
  ...Object.values(orderRoutes),
  ...Object.values(painelRoutes),
  ...Object.values(parameterRoutes),
  ...Object.values(permissionRoutes),
  ...Object.values(productRoutes),
  ...Object.values(regionRoutes),
  ...Object.values(roleRoutes),
  ...Object.values(reportRoutes),
  ...Object.values(stockRoutes),
  ...Object.values(userRoutes),
  ...Object.values(workOrderRoutes),
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (requiresAuthentication(to)) {
    useAuthStore().loadLoggedUser();
    const token = localStorage.getItem("token");
    if (!token) {
      redirectToLogin(next);
      return;
    }

    const userPermissions = useAuthStore().getAuthorities;
    const requiredPermissions = to.meta.requiresPermissions ?? [];
    requiredPermissions.every((permission) =>
      userPermissions.includes(permission)
    );
    if (
      requiresPermissions(to) &&
      !hasRequiredPermissions(to, userPermissions)
    ) {
      redirectToHome(next);
      return;
    }
  }

  if (to.path === "/" && isAuthenticated()) {
    redirectToHome(next);
    return;
  }

  next();
});

function requiresAuthentication(route) {
  return route.matched.some((record) => record.meta.requiresAuth);
}

function requiresPermissions(route) {
  return route.matched.some((record) => record.meta.requiresPermissions);
}

function hasRequiredPermissions(route, userPermissions) {
  const requiredPermissions = route.meta.requiresPermissions;
  return requiredPermissions.every((permission) =>
    userPermissions.includes(permission)
  );
}

function redirectToLogin(next) {
  if (router.currentRoute.path !== "/") {
    next({ path: "/" });
  } else {
    next();
  }
}

function redirectToHome(next) {
  if (router.currentRoute.path !== "/home") {
    next({ path: "/home" });
  } else {
    next();
  }
}

function isAuthenticated() {
  return !!localStorage.getItem("token");
}

router.getPathFromRouteName = function (routeName) {
  const route = this.options.routes.find((route) => route.name === routeName);
  return route ? route.path.replace(/\/:[^/]+/g, "") : null;
};

router.getRouteFromRouteName = function (routeName) {
  return this.options.routes.find((route) => route.name === routeName);
};

export default router;
