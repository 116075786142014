class Formatter {
  // Método estático para formatar percentuais
  static formatPercentage(value) {
    if (isNaN(value)) {
      return "";
    }
    return `${parseFloat(value).toFixed(2)}%`;
  }

  // Método estático para formatar valores monetários
  static formatCurrency(value, currency = "BRL", locale = "pt-BR") {
    if (isNaN(value)) {
      return "";
    }
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    }).format(value);
  }

  static formatDocument(document) {
    if (typeof document !== "string") {
      return "";
    }
    document = document.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    if (document.length === 11) {
      return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else if (document.length === 14) {
      return document.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    } else {
      return "";
    }
  }
}

export default Formatter;
