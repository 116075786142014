import { defineStore } from "pinia";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { regionRoutes } from "@/pages/region/router/index";
import RegionActions from "@/pages/region/enums/RegionActions";

import * as createAction from "@/pages/region/usecases/createAction.js";
import * as findPaginateAction from "@/pages/region/usecases/findPaginateAction.js";
import * as findAllAction from "@/pages/region/usecases/findAllAction.js";
import * as findAllByUserAction from "@/pages/region/usecases/findAllByUserAction.js";
import * as findByIdAction from "@/pages/region/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/region/usecases/updateByIdAction.js";
import * as updateLoteAction from "@/pages/region/usecases/updateLoteAction.js";
import * as deleteByIdAction from "@/pages/region/usecases/deleteByIdAction.js";
import * as deleteAllByIdAction from "@/pages/region/usecases/deleteAllByIdAction.js";
import * as searchAction from "@/pages/region/usecases/searchAction.js";

export const useRegionStore = defineStore("regionStore", {
  state: () => ({
    id: "",
    name: "",
    user: {},
    userId: "",
    list: [],
    listToPageUser: [],
    listLoaded: false,
    isLoading: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create(fields) {
      await createAction.create(this, fields);
    },

    async findPaginate(pagination, force) {
      await findPaginateAction.findPaginate(this, pagination, force);
    },

    async findAll() {
      return await findAllAction.findAll(this);
    },

    async findAllUser() {
      const regions = await findAllAction.findAll(this);
      this.listLoaded = true;
      this.listToPageUser = regions;
    },

    async findAllByUser(userId) {
      return await findAllByUserAction.findAllByUser(this, userId);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById(fields) {
      await updateByIdAction.updateById(this, fields.id, fields);
    },

    async updateLote(regions, userId) {
      await updateLoteAction.updateLote(this, regions, userId);
    },

    async deleteById(field, fields) {
      await deleteByIdAction.deleteById(this, field, fields);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    async search(fields) {
      await searchAction.search(this, fields);
    },

    refresh() {
      this.findPaginate({ page: this.getCurrentPage }, true);
    },

    setStore(fields) {
      this.id = fields.id;
      this.name = fields.name;
      this.user = fields.user;
      this.userId = fields.user?.id;
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(RegionActions.FIND_ALL_REGION);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    paginateValidation(force, pagination) {
      return !force && pagination.page === this.currentPage && this.listLoaded;
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(RegionActions.CREATE_REGION),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              regionRoutes.regionRouteCreateEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(RegionActions.FIND_ALL_REGION),
        new ValidateEditHandler(),
        (items) =>
          router.push(
            router.getPathFromRouteName(
              regionRoutes.regionRouteSearchEnum.name
            ) +
              "/" +
              items[0].id,
            null,
            () => {}
          )
      );
    },

    actionDelete() {
      return new Action(
        ActionEnum.DELETE.name,
        ActionEnum.DELETE.icon,
        ActionEnum.DELETE.action,
        this.hasPermission(RegionActions.DELETE_REGION),
        new ValidateDeleteHandler(),
        (items) => this.deleteAllById(items, this.getList)
      );
    },

    actionFilter() {
      return new Action(
        ActionEnum.FILTER.name,
        ActionEnum.FILTER.icon,
        ActionEnum.FILTER.action,
        this.hasPermission(RegionActions.SEARCH_REGION),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              regionRoutes.regionRouteSearchParametersEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(RegionActions.FIND_ALL_REGION),
        new ValidateActionHandler(),
        () => this.refresh()
      );
    },

    actionPaginate() {
      return new Action(
        ActionEnum.PAGINATE.name,
        ActionEnum.PAGINATE.icon,
        ActionEnum.PAGINATE.action,
        this.hasPermission(RegionActions.FIND_ALL_REGION),
        new ValidateActionHandler(),
        (pagination) => this.findPaginate(pagination)
      );
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(RegionActions.CREATE_REGION),
        new ValidateActionHandler(),
        (items) => this.create(items)
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(RegionActions.UPDATE_REGION),
        new ValidateActionHandler(),
        (items) => this.updateById(items)
      );
    },

    actionSearch() {
      return new Action(
        ActionEnum.SEARCH.name,
        ActionEnum.SEARCH.icon,
        ActionEnum.SEARCH.action,
        this.hasPermission(RegionActions.SEARCH_REGION),
        new ValidateActionHandler(),
        (items) => this.search(items)
      );
    },

    getMenuActions() {
      return [
        this.actionCreate(),
        this.actionEdit(),
        this.actionDelete(),
        this.actionFilter(),
      ];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionRefresh(),
        this.actionPaginate(),
        this.actionCreateSave(),
        this.actionEditSave(),
        this.actionSearch(),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getListByUser() {
      return this.$state.listUser;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findPaginate({ page: this.$state.currentPage });
      }
      return this.$state.list;
    },
    getListAll() {
      if (!this.$state.listLoaded) {
        this.findAllUser();
      }
      return this.$state.listToPageUser;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
