import {
  useSnackBarStore,
} from "@/components/snackbar/usecases/snackbarStore";
export class Action {

  constructor(name, icon, action, permission, validator, executor) {
    this.name = name;
    this.icon = icon;
    this.action = action;
    this.permission = permission;
    this.validator = validator;
    this.executor = executor;
    this.isVisibleInCardList = false;
  }

  setVibisibleInCardList() {
    this.isVisibleInCardList = true;
  }

  execute(items) {
    try {
      this.validator.validate(items);
      this.executor(items);
    } catch (error) {
      useSnackBarStore().mostrarSnackBar(useSnackBarStore().messageError(error));
    }
  }
}

