import { regionRouteCreateEnum } from '@/pages/region/router/regionRouteCreate'
import { regionRouteSearchEnum } from '@/pages/region/router/regionRouteSearch'
import { regionRouteListEnum } from '@/pages/region/router/regionRouteList'
import { regionRouteSearchParametersEnum } from '@/pages/region/router/regionRouteSearchParameters'


export const regionRoutes = {
    regionRouteCreateEnum,
    regionRouteSearchEnum,
    regionRouteListEnum,
    regionRouteSearchParametersEnum,
}
