import axiosInstance from "@/configs/axios";
import decode from "jwt-decode";
import router from "@/router/index";
import { defineStore } from "pinia";
import { routes } from "@/router/routes";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    id: "",
    email: "",
    name: "",
    tenant: "",
    initialsName: "",
    authorities: [],
    processing: false,
  }),
  actions: {
    async login(fields) {
      if (this.processing) return;

      this.processing = true;
      try {
        const user = await axiosInstance.post(routes.login, fields);
        this.setUserLogged(user.headers.authorization);
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Usuário logado com sucesso`)
        );
        router.push("/home");
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      } finally {
        this.processing = false;
      }
    },
    setUserLogged(token) {
      const tokenSubstr = token.substring(7);
      this.setUserDecoded(tokenSubstr);
      axiosInstance.defaults.headers.common["Authorization"] =
        "Bearer " + tokenSubstr;
      localStorage.setItem("token", tokenSubstr);
    },
    loadLoggedUser() {
      const token = localStorage.getItem("token");
      if (!token) return this.logout();

      if (!this.isTokenValid(token)) return this.logout();

      this.setUserDecoded(token);
    },
    logout() {
      this.email = "";
      this.name = "";
      this.tenant = "";
      this.authorities = []
      axiosInstance.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      router.push("/");
    },
    isTokenValid(token) {
      if (!token) return false;
      try {
        const { exp: expiration } = decode(token);
        const isExpired = !!expiration && Date.now() > expiration * 1000;

        if (isExpired) return false;
        return true;
      } catch (e) {
        return false;
      }
    },
    setUserDecoded(token) {
      if (!token) return;

      const decoded = decode(token);
      this.id = decoded.id;
      this.email = decoded.sub;
      this.name = decoded.name;
      this.tenant = decoded.tenant;
      this.initialsName = decoded.initialsName;
      this.authorities = decoded.authorities;
    },
  },
  getters: {
    getEmail: (state) => state.email,
    getName: (state) => state.name,
    getTenant: (state) => state.tenant,
    getId: (state) => state.id,
    getAuthorities: (state) => state.authorities.map(permission => permission.authority),
    getInitialsName: (state) => state.initialsName,
    isUserLogged() {
      return this.isTokenValid(localStorage.getItem("token"));
    },
  },
});
