import ComboActions from '@/pages/combo/enums/comboActions'

export const comboRouteListEnum =
{
  id: 'combo',
  path: "/combos",
  name: "combo",
  description: "Combos",
  order: 1,
  component: () => import("@/pages/combo/ComboPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ComboActions.FIND_ALL_COMBO]
  }
}
