import productActions from '@/pages/product/enums/productActions'

export const productRouteSearchParametersEnum =
{
  id: 'product',
  path: "/produto/pesquisa",
  name: "product-search-parameters",
  description: "Pesquisa produto com parâmetros",
  order: -1,
  component: () => import("@/pages/product/components/ProductFilter.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ productActions.SEARCH_PRODUCT ]
  }
}

