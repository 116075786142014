import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import router from "@/router/index";
import axiosInstance from "@/configs/axios";

export const updateById = async (store, id, fields) => {
  if (store.processing) return;

  store.processing = true;
  store.isLoading = true;
  try {
    await axiosInstance.put(`${routes.parameter}/${id}`, fields);
    await store.findPaginate({ page: store.$state.currentPage });
    useSnackBarStore().mostrarSnackBar(
      messageSuccess(`Parametrização atualizada com sucesso!`)
    );
    store.closeModal = true;
    router.back();
  } catch (e) {
    useSnackBarStore().mostrarSnackBar(messageError(error(e)));
    store.closeModal = false;
  } finally {
    store.isLoading = false;
    store.processing = false;
  }
};