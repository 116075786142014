<template>
  <v-container fluid>
    <CardList
      :name="'Pagamentos'"
      :headers="headers"
      :items="objectsList"
      :loading="paymentStore.getIsLoading"
      :menu="getMenu()"
      :refresh="false"
      @action="(items, aciton) => handleAction(items, aciton)"
    />
  </v-container>
</template>

<script>
import CardList from "@/components/CardList.vue";
import PaymentTypeEnum from "@/pages/payment/enums/PaymentTypeEnum";

import { usePaymentStore } from "@/pages/payment/usecases/index";
import { formatDateTime } from "@/utils/dateUtil";

export default {
  name: "PaymentPage",
  components: {
    CardList,
  },
  props: {
    objectsList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  setup() {
    const paymentStore = usePaymentStore();
    return { paymentStore };
  },
  data() {
    return {
      isUpdate: false,
      headers: [
        { text: "Data do Pagamento", align: "start", value: "paymentDate", formatter: this.formatDateTime },
        { text: "Tipo", value: "type", formatter: this.formatType },
        { text: "Valor", value: "paymentValue" },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    formatDateTime(date) {
      return formatDateTime(date);
    },
    formatType(value) {
      const typeEnum = PaymentTypeEnum.values().find(
        (status) => status.cod === value
      );
      return typeEnum ? typeEnum.description : value;
    },
    handleAction(items, action) {
      const listener = this.paymentStore.getActionsListener();
      const actionFinded = listener.find((a) => a.action === action);
      if (!actionFinded) return;
      actionFinded.execute(items);
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: this.paymentStore.getMenuActions(),
      };
    },
  },
};
</script>
