import UserActions from '@/pages/user/enums/UserActions'

export const userRouteSearchParametersEnum =
{
  id: 'user',
  path: "/usuario/pesquisa",
  name: "user-search-parameters",
  description: "Pesquisa usuário com parâmetros",
  order: -1,
  component: () => import("@/pages/user/components/UserFilter.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ UserActions.SEARCH_USER ]
  }
}

