import CustomerActions from '@/pages/customer/enums/CustomerActions'

export const customerRouteSearchEnum =
{
  id: 'customer',
  path: "/cliente/pesquisa/:id",
  name: "customer-pesquisa",
  description: "Pesquisa cliente",
  order: -1,
  component: () => import("@/pages/customer/components/CustomerForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ CustomerActions.FIND_CUSTOMER_BY_ID, CustomerActions.FIND_CUSTOMER_CESTA_BY_ID]
  }
}

