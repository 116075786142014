import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import router from "@/router/index";
import axiosInstance from "@/configs/axios";

export const create = async (store, fields) => {
    if (store.processing) return;

    store.processing = true;
    store.isLoading = true;
    store.setStore(fields);
    try {
        await axiosInstance.post(routes.product, store.$state);
        store.refresh();
        useSnackBarStore().mostrarSnackBar(
            messageSuccess(`Produto cadastrado com sucesso!`)
        );
        router.back()
        store.closeModal = true;
    } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        store.closeModal = false;
    } finally {
        store.isLoading = false;
        store.processing = false;
    }
};