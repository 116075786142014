import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  maxContentLength: 100 * 1024 * 1024,
  maxBodyLength: 100 * 1024 * 1024,
})

const token = localStorage.getItem('token')
axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token

export default axiosInstance