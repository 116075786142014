import { defineStore } from "pinia";

import { useAuthStore } from "@/pages/auth/usecases/index";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import ReportActions from "@/pages/report/enums/ReportActions";
import DefaultForm from "@/pages/report/components/DefaultForm.vue";

import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error, errorMedia } from "@/utils/exceptionError";
import { pdfDownload } from "@/utils/support";

export const useReportStore = defineStore("reportStore", {
  state: () => ({
    id: null,
    title: null,
    description: null,
    params: {},
    list: [],
    listLoaded: false,
    closeModal: false,
    processing: false,
    isLoading: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async findAll() {
      this.processing = true;
      this.isLoading = true;
      try {
        const response = await axiosInstance.get(routes.report);
        this.list = response.data;
        this.listLoaded = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      } finally {
        this.processing = false;
        this.isLoading = false;
      }
    },

    async print(item, params) {
      if (this.processing) return;

      this.processing = true;
      this.isLoading = true;
      try {
        const response = await axiosInstance.get(
          `${routes.report}/print/${item.id}`,
          {
            responseType: "blob",
            params,
          }
        );
        pdfDownload(response, "relatorio.pdf");
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(await errorMedia(e)));
      } finally {
        this.processing = false;
        this.isLoading = false;
      }
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(ReportActions.FIND_ALL_REPORT);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    actionPrintReport() {
      const action = new Action(
        "Imprimir relatório",
        "mdi-file-pdf-box",
        "submitReportPdf",
        this.hasPermission(ReportActions.SUBMIT_REPORT_PDF),
        new ValidateEditHandler(),
        (items) => {
          useDialogStore().openDialogWithData({
            title: "Informações do relatório",
            childComponent: DefaultForm,
            data: items[0],
          });
        }
      );
      action.setVibisibleInCardList();
      return action;
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(ReportActions.FIND_ALL_REPORT),
        new ValidateActionHandler(),
        () => this.findAll()
      );
    },

    getMenuActions() {
      return [this.actionPrintReport()];
    },
    getActionsListener() {
      return [...this.getMenuActions(), this.actionRefresh()];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getCloseModal() {
      return this.$state.closeModal;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findAll();
      }
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
