import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";
import axiosInstance from "@/configs/axios";
import { useCityStore } from "@/pages/city/usecases/index";

export const getViaCep = async (zip) => {
    let address = {};
    try {
        address = await axiosInstance.get(
            `https://viacep.com.br/ws/${zip}/json/`
        );
    } catch (e) {
        console.log(e)
        useSnackBarStore().mostrarSnackBar(
            messageError(error("Não foi possível buscar esse cep"))
        );
    }
    return address;
}

export const findAddressViaCep = async (fields, vue) => {
    if (fields === undefined) return null;
    const zip = fields.zip;
    const normalizedZip = zip.replace(/\D/g, "");
    if (normalizedZip.length !== 8) return null;

    const address = await getViaCep(normalizedZip);
    const city = await useCityStore().getCity(address.data.localidade);
    fields.zip = address.data.cep;
    fields.district = address.data.bairro;
    fields.streetAddress = address.data.logradouro;
    fields.city = city.data.pop();
    vue.$forceUpdate();

    return fields;
}

