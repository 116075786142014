import { orderRouteCreateEnum } from '@/pages/order/router/orderRouteCreate'
import { orderRouteSearchEnum } from '@/pages/order/router/orderRouteSearch'
import { orderRouteListEnum } from '@/pages/order/router/orderRouteList'
import { orderRouteSearchParametersEnum } from '@/pages/order/router/orderRouteSearchParameters'


export const orderRoutes = {
    orderRouteCreateEnum,
    orderRouteSearchEnum,
    orderRouteListEnum,
    orderRouteSearchParametersEnum,
}
