import OrderActions from '@/pages/order/enums/OrderActions'

export const orderRouteListEnum =
{
  id: 'order',
  path: "/pedidos",
  name: "order",
  description: "Pedidos",
  order: 1,
  component: () => import("@/pages/order/OrderPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ OrderActions.FIND_ORDER_ALL ]
  }
}
