import { workOrderRouteCreateEnum } from '@/pages/workOrder/router/workOrderRouteCreate'
import { workOrderRouteSearchEnum } from '@/pages/workOrder/router/workOrderRouteSearch'
import { workOrderRouteListEnum } from '@/pages/workOrder/router/workOrderRouteList'
import { workOrderRouteSearchParametersEnum } from '@/pages/workOrder/router/workOrderRouteSearchParameters'


export const workOrderRoutes = {
    workOrderRouteCreateEnum,
    workOrderRouteSearchEnum,
    workOrderRouteListEnum,
    workOrderRouteSearchParametersEnum,
}
