import { defineStore } from "pinia";
import axiosInstance from "@/configs/axios";
import { routes } from "@/router/routes";
import { getGeolocation } from "@/utils/routesMap";
import { useAuthStore } from "@/pages/auth/usecases/index";
import BillingRouteActions from "@/pages/billingRoute/enums/BillingRouteActions";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import { openMapsItem } from "@/utils/routesMap";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";
import CustomerForm from "@/pages/customer/components/CustomerForm.vue";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";

export const useRotaDeEntregaStore = defineStore("rotaDeEntregaStore", {
  state: () => ({
    list: [],
    listLoaded: false,
    closeModal: false,
    isLoading: false,
    authorities: [],
  }),
  actions: {
    async findAll() {
      this.isLoading = true;
      try {
        const coords = await getGeolocation();
        const response = await axiosInstance.get(`${routes.rotas}/entrega`, {
          params: {
            latitude: coords.latitude,
            longitude: coords.longitude,
          },
        });
        this.list = response.data;
        this.listLoaded = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      } finally {
        this.isLoading = false;
      }
    },
    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(
        BillingRouteActions.PROCURA_TODAS_ROTAS_DE_ENTREGA
      );
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    actionOpenMaps() {
      const action = new Action(
        "Abrir no maps",
        "mdi-google-maps",
        "openGoogleMap",
        this.hasPermission(BillingRouteActions.PROCURA_TODAS_ROTAS_DE_ENTREGA),
        new ValidateEditHandler("Deve selecionar um cliente para abrir no mapa."),
        (items) => {
          openMapsItem(items[0]);
        }
      );
      action.setVibisibleInCardList();
      return action;
    },

    actionOpenCliente() {
      const executable = (items) => {
        useDialogStore().openDialogWithData({
          title: "Cliente",
          childComponent: CustomerForm,
          isUpdate: true,
          data: items[0],
        });
      };
      const action = new Action(
        "Abrir cliente",
        "mdi-account",
        ActionEnum.EDIT.action,
        true,
        new ValidateEditHandler("Deve selecionar um cliente."),
        executable
      );
      action.setVibisibleInCardList();
      return action;
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(BillingRouteActions.PROCURA_TODAS_ROTAS_DE_ENTREGA),
        new ValidateActionHandler(),
        () => this.findAll()
      );
    },

    getMenuActions() {
      return [this.actionOpenCliente(), this.actionOpenMaps()];
    },
    getActionsListener() {
      return [...this.getMenuActions(), this.actionRefresh()];
    },
  },
  getters: {
    getCloseModal() {
      return this.$state.closeModal;
    },
    getIsLoading() {
      return this.$state.isLoading;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findAll();
      }
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
