import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export const openWhatsapp = async (number) => {
    const phoneNumber = parsePhoneNumberFromString(number, "BR");

    if (!phoneNumber || !phoneNumber.isValid()) {
        useSnackBarStore().mostrarSnackBar(
            messageError(error("Número de celular é inválido"))
        );
        return;
    }

    const url = `https://api.whatsapp.com/send?phone=${phoneNumber.nationalNumber}`;
    window.open(url, "_blank");

    useSnackBarStore().mostrarSnackBar(
        messageSuccess("WhatsApp aberto com sucesso!")
    );
}