import BillingRouteActions from '@/pages/billingRoute/enums/BillingRouteActions'

export const billingRouteListEnum =
{
  id: 'billing',
  path: "/cobrancas",
  name: "billing",
  description: "Rotas de dobranças",
  order: 1,
  component: () => import("@/pages/billingRoute/BillingRoutePage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ BillingRouteActions.FIND_ALL_BILLING_ROUTE ]
  }
}