export default class ParameterActions {
    static FIND_PARAMETER_BY_ID = "FIND_PARAMETER_BY_ID";
    static FIND_PARAMETER_BY_NAME = "FIND_PARAMETER_BY_NAME";
    static FIND_PARAMETER_ALL = "FIND_PARAMETER_ALL";
    static CREATE_PARAMETER = "CREATE_PARAMETER";
    static UPDATE_PARAMETER = "UPDATE_PARAMETER";
    static DELETE_PARAMETER = "DELETE_PARAMETER";

    static values() {
        return [
            ParameterActions.FIND_PARAMETER_BY_ID,
            ParameterActions.FIND_PARAMETER_BY_NAME,
            ParameterActions.FIND_PARAMETER_ALL,
            ParameterActions.CREATE_PARAMETER,
            ParameterActions.UPDATE_PARAMETER,
            ParameterActions.DELETE_PARAMETER,
        ];
    }
}

