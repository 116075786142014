<template>
  <v-container fluid>
    <CardList
      :name="'Lançamentos'"
      :headers="headers"
      :items="objectsList"
      :refresh="false"
      :menu="getMenu()"
    />
  </v-container>
</template>

<script>
import CardList from "@/components/CardList.vue";
import EntryMotivatorEnum from "@/pages/entry/enums/EntryMotivatorEnum";
import EntryTypeEnum from "@/pages/entry/enums/EntryTypeEnum";

export default {
  name: "EntryPage",
  components: {
    CardList,
  },
  props: {
    objectsList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      headers: [
        { text: "Valor do lançamento", align: "start", value: "entryValue" },
        {
          text: "Data do lançamento",
          value: "entryDate",
          formatter: this.formatDateTime,
        },
        {
          text: "Tipo do lançamento",
          value: "entryType",
          formatter: this.formatType,
        },
        {
          text: "Motivo do lançamento",
          value: "entryMotivator",
          formatter: this.formatMotivator,
        },
        { text: "Descrição", value: "description" },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      isUpdate: false,
    };
  },
  methods: {
    formatType(value) {
      const typeEnum = EntryTypeEnum.values().find(
        (status) => status.cod === value
      );
      return typeEnum ? typeEnum.description : value;
    },
    formatMotivator(value) {
      const statusEnum = EntryMotivatorEnum.values().find(
        (status) => status.cod === value
      );
      return statusEnum ? statusEnum.description : value;
    },
    formatDateTime(value) {
      const data = new Date(value);

      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const formatoBrasileiro = new Intl.DateTimeFormat("pt-BR", options);

      return formatoBrasileiro.format(data);
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: [],
      };
    },
  },
};
</script>
