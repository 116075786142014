import ProductActions from '@/pages/product/enums/productActions'

export const productRouteListEnum =
{
  id: 'product',
  path: "/produtos",
  name: "product",
  description: "Produtos",
  order: 1,
  component: () => import("@/pages/product/ProductPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ProductActions.FIND_ALL_PRODUCT ]
  }
}
