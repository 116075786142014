<template>
  <v-btn
    :color="color"
    :class="styles"
    :loading="loading"
    @click="handleFunction"
    v-if="permission"
    :disabled="disabled"
    :outlined="outlined"
    v-bind="attrs"
    v-on="on"
  >
    <v-icon v-if="icon && isIconBeforeText" class="mx-1" :small="!!name">
      {{ icon }}
    </v-icon>
    {{ name }}
    <v-icon v-if="icon && !isIconBeforeText" class="mx-1" :small="!!name">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "Button",
  props: {
    name: {
      type: String,
      required: false,
    },
    handleFunction: {
      type: Function,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    permission: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    styles: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true,
    },
    attrs: {
      type: Object,
      required: false,
    },
    on: {
      type: Object,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    isIconBeforeText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
