import RegionActions from '@/pages/region/enums/RegionActions'

export const regionRouteListEnum =
{
  id: 'region',
  path: "/regioes",
  name: "region",
  description: "Regiões",
  order: 1,
  component: () => import("@/pages/region/RegionPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ RegionActions.FIND_ALL_REGION ]
  }
}
