import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import router from "@/router/index";
import axiosInstance from "@/configs/axios";

export const updateById = async (store) => {
    if (store.processing) return;
    store.processing = true;
    store.isLoading = true;
    const formData = store.$state.formData
    try {
      await axiosInstance.put(`${routes.order}/${formData.id}`, formData);
      useSnackBarStore().mostrarSnackBar(
        messageSuccess(`Pedido atualizado com sucesso!`)
      );
      router.back();
      store.closeModal = true;
    } catch (e) {
      useSnackBarStore().mostrarSnackBar(messageError(error(e)));
      store.closeModal = false;
    } finally {
      store.isLoading = false;
      store.processing = false;
    }
};