import { parameterRouteCreateEnum } from '@/pages/parameter/router/parameterRouteCreate'
import { parameterRouteSearchEnum } from '@/pages/parameter/router/parameterRouteSearch'
import { parameterRouteListEnum } from '@/pages/parameter/router/parameterRouteList'


export const parameterRoutes = {
    parameterRouteCreateEnum,
    parameterRouteSearchEnum,
    parameterRouteListEnum,
}
