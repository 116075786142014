export const messageSuccess = (message) => {
  return {
    content: message,
    color: "green",
  };
};

export const messageError = (message) => {
  return {
    content: message,
    color: "error",
  };
};

function errorFormated(errors) {
  const messages = [];
  errors.forEach(error => {
    messages.push(error.message);
  });
  return messages.join('\n');
}

export const error = (error) => {
  const message =
    error.response === undefined
      ? error
      : error.response.data.errors !== undefined
      ? errorFormated(error.response.data.errors)
      : error.response.data.message;

  return message || "Ocorreu um erro inesperado";
};

export const errorMedia = async (error) => {
  const text = await error.response.data.text()
  const json = JSON.parse(text)
  let message = json.message;

  return message || "Ocorreu um erro inesperado";
};
