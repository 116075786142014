import { defineStore } from "pinia";

export const useDialogStore = defineStore("dialogStore", {
    state: () => ({
        id: null,
        title: '',
        dialog: false,
        isUpdate: false,
        menu: {
            items: [],
        },
        childComponent: {},
        data: {},
        openDialogs: [],
    }),
    getters: {
        getDialogAtivo() {
            return this.openDialogs[this.openDialogs.length - 1]
        }
    },
    actions: {
        openDialogWithData(payload) {
            this.id = this.generateId()
            this.title = payload.title
            this.dialog = true
            this.isUpdate = payload.isUpdate
            this.childComponent = payload.childComponent
            this.data = payload.data

            if (payload.menu)
                this.menu = payload.menu

            this.openDialogs.push({
                id: this.id,
                title: this.title,
                dialog: this.dialog,
                isUpdate: this.isUpdate,
                menu: this.menu,
                childComponent: this.childComponent,
                data: this.data
            })
        },
        closeDialog() {
            this.openDialogs.pop()
        },
        generateId() {
            const timestamp = (new Date()).getTime();
            const randomNum = Math.floor(Math.random() * 1000);
            const uniqueId = `${timestamp}${randomNum}`;
            return uniqueId;
        }
    },
});
