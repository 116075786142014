<template>
  <v-snackbar
    top
    v-model="snackBarStore.snack"
    :timeout="3000"
    :color="snackBarStore.color"
    data-cy="snackbar-field"
    style="white-space: pre-line"
  >
    <span>{{
      snackBarStore.content == undefined
        ? "Ocorreu um problema! Contate o desenvolvedor"
        : snackBarStore.content
    }}</span>
  </v-snackbar>
</template>

<script>
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";

export default {
  name: "SnackBarComponent",
  setup() {
    const snackBarStore = useSnackBarStore();
    return {
      snackBarStore,
    };
  },
};
</script>
