import ProductActions from '@/pages/product/enums/productActions'

export const productRouteCreateEnum =
{
  id: 'product',
  path: "/produto/novo",
  name: "product-novo",
  description: "Novo produto",
  order: -1,
  component: () => import("@/pages/product/components/ProductForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ProductActions.CREATE_PRODUCT ]
  }
}
