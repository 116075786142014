import PermissionActions from '@/pages/permission/enums/PermissionActions'

export const permissionRouteCreateEnum =
{
  id: 'permission',
  path: "/permissao/novo",
  name: "permission-novo",
  description: "Nova permissão",
  order: -1,
  component: () => import("@/pages/permission/components/PermissionForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ PermissionActions.CREATE_PERMISSION ]
  }
}
