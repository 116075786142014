export default class CustomerActions {
    static FIND_CUSTOMER_BY_ID = "FIND_CUSTOMER_BY_ID";
    static FIND_CUSTOMER_BY_EMAIL = "FIND_CUSTOMER_BY_EMAIL";
    static FIND_CUSTOMER_BY_CPF_CNPJ = "FIND_CUSTOMER_BY_CPF_CNPJ";
    static FIND_ALL_CUSTOMER = "FIND_ALL_CUSTOMER";
    static SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
    static FIND_ALL_CUSTOMER_BY_CESTA = "FIND_ALL_CUSTOMER_BY_CESTA";
    static FIND_CUSTOMER_CESTA_BY_ID = "FIND_CUSTOMER_CESTA_BY_ID";
    static SEARCH_CUSTOMER_CESTA = "SEARCH_CUSTOMER_CESTA";
    static CREATE_CUSTOMER = "CREATE_CUSTOMER";
    static CREATE_CUSTOMER_BY_CESTA = "CREATE_CUSTOMER_BY_CESTA";
    static UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
    static UPDATE_CUSTOMER_BY_CESTA = "UPDATE_CUSTOMER_BY_CESTA";
    static DISABLE_CUSTOMER = "DISABLE_CUSTOMER";

    static values() {
        return [
            CustomerActions.FIND_CUSTOMER_BY_ID,
            CustomerActions.FIND_CUSTOMER_BY_EMAIL,
            CustomerActions.FIND_CUSTOMER_BY_CPF_CNPJ,
            CustomerActions.FIND_ALL_CUSTOMER,
            CustomerActions.SEARCH_CUSTOMER,
            CustomerActions.FIND_ALL_CUSTOMER_BY_CESTA,
            CustomerActions.FIND_CUSTOMER_CESTA_BY_ID,
            CustomerActions.SEARCH_CUSTOMER_CESTA,
            CustomerActions.CREATE_CUSTOMER,
            CustomerActions.CREATE_CUSTOMER_BY_CESTA,
            CustomerActions.UPDATE_CUSTOMER,
            CustomerActions.UPDATE_CUSTOMER_BY_CESTA,
            CustomerActions.DISABLE_CUSTOMER,
        ];
    }
}

