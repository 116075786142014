export default class UserActions {
    static FIND_USER_BY_ID = 'FIND_USER_BY_ID';
    static FIND_USER_CESTA_BY_ID = 'FIND_USER_CESTA_BY_ID';
    static FIND_USER_BY_EMAIL = 'FIND_USER_BY_EMAIL';
    static FIND_USER_ALL = 'FIND_USER_ALL';
    static FIND_USER_CESTA_ALL = 'FIND_USER_CESTA_ALL';
    static SEARCH_CESTA_USER = 'SEARCH_CESTA_USER';
    static SEARCH_USER = 'SEARCH_USER';
    static CREATE_USER = 'CREATE_USER';
    static CREATE_CESTA_USER = 'CREATE_CESTA_USER';
    static UPDATE_USER = 'UPDATE_USER';
    static UPDATE_CESTA_USER = 'UPDATE_CESTA_USER';
    static DELETE_USER = 'DELETE_USER';

    static values() {
        return [
            UserActions.FIND_USER_BY_ID,
            UserActions.FIND_USER_CESTA_BY_ID,
            UserActions.FIND_USER_BY_EMAIL,
            UserActions.FIND_USER_ALL,
            UserActions.FIND_USER_CESTA_ALL,
            UserActions.SEARCH_CESTA_USER,
            UserActions.CREATE_USER,
            UserActions.CREATE_CESTA_USER,
            UserActions.UPDATE_USER,
            UserActions.UPDATE_CESTA_USER,
            UserActions.DELETE_USER,
        ];
    }
}

