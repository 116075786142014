import { defineStore } from "pinia";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { useContactStore } from "@/pages/contact/usecases/index";
import { useAddressStore } from "@/pages/address/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { userRoutes } from "@/pages/user/router/index";
import UserActions from "@/pages/user/enums/UserActions";

import * as createAction from "@/pages/user/usecases/createAction.js";
import * as findPaginateAction from "@/pages/user/usecases/findPaginateAction.js";
import * as findAllAction from "@/pages/user/usecases/findAllAction.js";
import * as findByIdAction from "@/pages/user/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/user/usecases/updateByIdAction.js";
import * as deleteAllByIdAction from "@/pages/user/usecases/deleteAllByIdAction.js";
import * as searchAction from "@/pages/user/usecases/searchAction.js";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    formData: {
      tenant: null,
      name: null,
      email: null,
      cpfOuCnpj: null,
      type: null,
      address: [],
      contacts: [],
      status: null,
      roles: [],
      authorities: [],
      password: null,
      regions: [],
    },
    list: [],
    listLoaded: false,
    isLoading: false,
    closeModal: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create() {
      await createAction.create(this);
    },

    async findPaginate(pagination, force) {
      await findPaginateAction.findPaginate(this, pagination, force);
    },

    async findAll() {
      return await findAllAction.findAll(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById() {
      await updateByIdAction.updateById(this);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    async search(fields) {
      await searchAction.search(this, fields);
    },

    refresh() {
      this.findPaginate({ page: this.getCurrentPage }, true);
    },

    getUser(object) {
      return {
        id: object?.id,
        tenant: object?.tenant,
        name: object?.person?.name,
        email: object?.person?.email,
        cpfOuCnpj: object?.person?.cpfOuCnpj,
        type: object?.person?.type,
        status: object?.person?.status,
        regions: object?.regions,
        personId: object?.personId,
        authorities: object?.authorities,
        roles: object?.roles,
        contacts: object?.person?.contacts,
        address: object?.person?.address?.map((element) => {
          element.cityId = element.city?.id;
          element.regionId = element.region?.id;
          return element;
        }),
      };
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(UserActions.FIND_USER_ALL);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    paginateValidation(force, pagination) {
      return !force && pagination.page === this.currentPage && this.listLoaded;
    },

    addContact(contact) {
      return this.formData.contacts.push(contact);
    },

    addAddress(contact) {
      return this.formData.address.push(contact);
    },

    cleanData() {
      this.formData.tenant = null;
      this.formData.name = null;
      this.formData.email = null;
      this.formData.cpfOuCnpj = null;
      this.formData.type = null;
      this.formData.address = [];
      this.formData.contacts = [];
      this.formData.status = null;
      this.formData.authorities = [];
      this.formData.roles = [];
      this.formData.password = null;
      this.formData.regions = [];
      useContactStore().cleanData();
      useContactStore().resetIds();
      useAddressStore().cleanData();
      useAddressStore().resetIds();
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(UserActions.CREATE_USER),
        new ValidateActionHandler(),
        () => {
          this.cleanData();
          router.push(
            router.getPathFromRouteName(userRoutes.userRouteCreateEnum.name),
            null,
            () => {}
          );
        }
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(
          UserActions.UPDATE_USER || UserActions.UPDATE_CESTA_USER
        ),
        new ValidateEditHandler(),
        (items) =>
          router.push(
            router.getPathFromRouteName(userRoutes.userRouteSearchEnum.name) +
              "/" +
              items[0].id,
            null,
            () => {}
          )
      );
    },

    actionDisable() {
      return new Action(
        ActionEnum.DISABLE.name,
        ActionEnum.DISABLE.icon,
        ActionEnum.DISABLE.action,
        this.hasPermission(UserActions.DELETE_USER),
        new ValidateDeleteHandler(),
        (items) => this.deleteAllById(items, this.getList)
      );
    },

    actionFilter() {
      return new Action(
        ActionEnum.FILTER.name,
        ActionEnum.FILTER.icon,
        ActionEnum.FILTER.action,
        this.hasPermission(UserActions.SEARCH_USER),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              userRoutes.userRouteSearchParametersEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(UserActions.FIND_USER_ALL),
        new ValidateActionHandler(),
        () => this.refresh()
      );
    },

    actionPaginate() {
      return new Action(
        ActionEnum.PAGINATE.name,
        ActionEnum.PAGINATE.icon,
        ActionEnum.PAGINATE.action,
        this.hasPermission(UserActions.FIND_USER_ALL),
        new ValidateActionHandler(),
        (pagination) => this.findPaginate(pagination)
      );
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(UserActions.CREATE_USER),
        new ValidateActionHandler(),
        (items) => this.create()
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(UserActions.UPDATE_USER),
        new ValidateActionHandler(),
        () => this.updateById()
      );
    },

    actionSearch() {
      return new Action(
        ActionEnum.SEARCH.name,
        ActionEnum.SEARCH.icon,
        ActionEnum.SEARCH.action,
        this.hasPermission(UserActions.SEARCH_USER),
        new ValidateActionHandler(),
        (items) => this.search(items)
      );
    },

    getMenuActions() {
      return [
        this.actionCreate(),
        this.actionEdit(),
        this.actionDisable(),
        this.actionFilter(),
      ];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionRefresh(),
        this.actionPaginate(),
        this.actionCreateSave(),
        this.actionEditSave(),
        this.actionSearch(),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getCloseModal() {
      return this.$state.closeModal;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findPaginate({ page: this.$state.currentPage });
      }
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
