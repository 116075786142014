<template>
  <div>
    <v-app-bar app clipped-left color="white">
      <router-link :to="'/home'">
        <v-img
          class="mx-2"
          src="/logo.png"
          max-height="70"
          max-width="70"
        ></v-img>
      </router-link>
      <v-btn text @click="drawer = !drawer" data-cy="nav-btn" color="primary">
        <v-icon left>mdi-apps</v-icon>
        Serviços
      </v-btn>

      <v-spacer></v-spacer>

      <Profile @openEditAvatar="() => editItem()" />
    </v-app-bar>

    <v-navigation-drawer app clipped v-model="drawer" color="white">
      <v-list flat>
        <template v-for="(group, index) in items">
          <v-subheader :key="'header-' + index">{{ group.title }}</v-subheader>
          <template v-for="item in group.items">
            <template v-if="item.permission">
              <v-list-item
                :key="item.title"
                :to="item.to"
                class="v-list-item--hover"
                active-class="v-list-item--active"
                exact-active-class="v-list-item--active"
              >
                <v-list-item-icon>
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="primary--text">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { useMenuItemsStore } from "@/components/menu/usecases/menuStore";

import Profile from "@/components/profile/Profile.vue";

export default {
  name: "NavigationDrawerComponent",
  components: {
    Profile,
  },
  data() {
    return {
      drawer: false,
      group: null,
      items: useMenuItemsStore().menuItems(),
    };
  },
  setup() {},
  methods: {},

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped>
.v-list-item:hover {
  background-color: #f5f5f5;
}

.v-list-item--active {
  background-color: #e0e0e0;
}
</style>
