import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";

export const findAll = async (store) => {
    store.isLoading = true;
    try {
        const response = await axiosInstance.get(routes.parameter);
        return response.data.content;
    } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
    } finally {
        store.isLoading = false;
    }
};