export default class AddressActions {
  static FIND_ADDRESS_BY_ID = "FIND_ADDRESS_BY_ID";
  static FIND_ALL_ADDRESS = "FIND_ALL_ADDRESS";
  static CREATE_ADDRESS = "CREATE_ADDRESS";
  static CREATE_CESTA_ADDRESS = "CREATE_CESTA_ADDRESS";
  static UPDATE_ADDRESS = "UPDATE_ADDRESS";
  static UPDATE_CESTA_ADDRESS = "UPDATE_CESTA_ADDRESS";
  static DELETE_ADDRESS = "DELETE_ADDRESS";

  static ACAO_BUSCA_ENDERECO_RURAL_POR_ID = "BuscaEnderecoRuralPorId";
  static ACAO_BUSCA_TODOS_ENDERECOS_RURAIS = "BuscaTodosEnderecosRurais";
  static ACAO_CRIA_ENDERECO_RURAL = "CriaEnderecoRural";
  static ACAO_ATUALIZA_ENDERECO_RURAL = "AtualizaEnderecoRural";
  static ACAO_DELETA_ENDERECO_RURAL = "DeletaEnderecoRural";

  static values() {
    return [
      AddressActions.FIND_ADDRESS_BY_ID,
      AddressActions.FIND_ALL_ADDRESS,
      AddressActions.CREATE_ADDRESS,
      AddressActions.CREATE_CESTA_ADDRESS,
      AddressActions.UPDATE_ADDRESS,
      AddressActions.UPDATE_CESTA_ADDRESS,
      AddressActions.DELETE_ADDRESS,

      AddressActions.ACAO_BUSCA_ENDERECO_RURAL_POR_ID,
      AddressActions.ACAO_BUSCA_TODOS_ENDERECOS_RURAIS,
      AddressActions.ACAO_CRIA_ENDERECO_RURAL,
      AddressActions.ACAO_ATUALIZA_ENDERECO_RURAL,
      AddressActions.ACAO_DELETA_ENDERECO_RURAL,
    ];
  }
}
