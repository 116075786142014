import ComboActions from '@/pages/combo/enums/comboActions'

export const comboRouteSearchEnum =
{
  id: 'combo',
  path: "/combo/pesquisa/:id",
  name: "combo-pesquisa",
  description: "Pesquisa combo",
  order: -1,
  component: () => import("@/pages/combo/components/ComboForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ComboActions.FIND_COMBO_BY_ID ]
  }
}

