import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import router from "@/router/index";
import axiosInstance from "@/configs/axios";

export const search = async (store, fields) => {
  store.isLoading = true;
  try {
    const response = await axiosInstance.get(`${routes.stock}/search`, {
      params: {
        entryDate: fields.entryDate,
        productId: fields.productId,
      },
    });
    const data = response.data;
    store.list = data.content;
    store.totalPages = data.totalPages;
    store.totalElements = data.totalElements;
    store.currentPage = 1;
    store.listLoaded = true;
    router.back()
  } catch (e) {
    useSnackBarStore().mostrarSnackBar(messageError(error(e)));
  } finally {
    store.isLoading = false;
  }
};