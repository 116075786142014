import ParameterActions from '@/pages/parameter/enums/ParameterActions'

export const parameterRouteSearchEnum =
{
  id: 'parameter',
  path: "/parametro/pesquisa/:id",
  name: "parameter-pesquisa",
  description: "Pesquisa parâmetro",
  order: -1,
  component: () => import("@/pages/parameter/components/ParameterForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ParameterActions.FIND_PARAMETER_BY_ID ]
  }
}

