import CustomerActions from '@/pages/customer/enums/CustomerActions'

export const customerRouteCreateEnum =
{
  id: 'customer',
  path: "/cliente/novo",
  name: "customer-novo",
  description: "Novo cliente",
  order: -1,
  component: () => import("@/pages/customer/components/CustomerForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ CustomerActions.CREATE_CUSTOMER, CustomerActions.CREATE_CUSTOMER_BY_CESTA ]
  }
}
