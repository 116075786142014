import CustomerActions from '@/pages/customer/enums/CustomerActions'

export const customerRouteListEnum =
{
  id: 'customer',
  path: "/clientes",
  name: "customer",
  description: "Clientes",
  order: 1,
  component: () => import("@/pages/customer/CustomerPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ CustomerActions.FIND_ALL_CUSTOMER, CustomerActions.FIND_ALL_CUSTOMER_BY_CESTA ]
  }
}
