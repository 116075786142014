const today = new Date();
const monthDate = today.getMonth() < 10 ? `0${today.getMonth() + 1}` : `${today.getMonth() + 1}`;
const dayDate = today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`;

export const todayDateField = `${today.getFullYear()}-${monthDate}-${dayDate}`;

const yesterday = new Date(Date.now() - 86400000)
const monthYesterday = yesterday.getMonth() < 10 ? `0${yesterday.getMonth() + 1}` : `${yesterday.getMonth() + 1}`;
const dayYesterday = yesterday.getDate() < 10 ? `0${yesterday.getDate()}` : `${yesterday.getDate()}`;

export const yesterdayDateField = `${yesterday.getFullYear()}-${monthYesterday}-${dayYesterday}`;


export const formatDateTime = (value) => {
    const date = new Date(value);
    const monthDate = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const dayDate = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    return `${dayDate}/${monthDate}/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
};


export const formatDate = (value) => {
    const parts = value.split('-');
    const date = new Date(parts[0], parts[1] - 1, parts[2]);
    const monthDate = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const dayDate = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    return `${dayDate}/${monthDate}/${date.getFullYear()}`
};