import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
            light: {
                primary: '#112F5D', // cor primária
                secondary: '#424242', // cor secundária
                accent: '#82B1FF', // cor de destaque
                error: '#FF5252', // cor de erro
                info: '#2196F3', // cor de informação
                success: '#4CAF50', // cor de sucesso
                warning: '#FFC107', // cor de aviso
                white: '#FFFFFF', // cor branca
            },
        },
    },
});
