import AddressActions from "@/pages/address/enums/AddressActions";

export const addressRouteCreateEnum = {
  id: "address",
  path: "/endreco/novo",
  name: "address-novo",
  description: "Novo endereço",
  order: -1,
  component: () => import("@/pages/address/components/AddressForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [
      AddressActions.CREATE_ADDRESS,
      AddressActions.CREATE_CESTA_ADDRESS,
    ],
  },
};

export const addressRouteSearchEnum = {
  id: "address",
  path: "/endereco/pesquisa/:id",
  name: "address-pesquisa",
  description: "Pesquisa endereço",
  order: -1,
  component: () => import("@/pages/address/components/AddressForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [AddressActions.FIND_ADDRESS_BY_ID],
  },
};

export const addressRouteListEnum = {
  id: "address",
  path: "/enderecos",
  name: "addresses",
  description: "Enderecos",
  order: 1,
  component: () => import("@/pages/address/AddressPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [AddressActions.FIND_ALL_ADDRESS],
  },
};

export const addressRoutes = {
  addressRouteCreateEnum,
  addressRouteSearchEnum,
  addressRouteListEnum,
};
