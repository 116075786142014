<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
        v-for="(image, index) in images"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card class="mx-auto" max-width="400">
          <v-img :src="image.path" height="200">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-title>{{ image.originalName }}</v-card-title>
          <v-card-actions class="d-flex justify-end align-center">
            <Button
              :name="'Remover'"
              :handleFunction="() => clickRemoveImage(image)"
              :permission="permission"
              :outlined="false"
              :color="'primary'"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ActionEnum from "@/domain/action/ActionEnum";
import Button from "@/components/Button.vue";
export default {
  components: {
    Button,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    permission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedImages: [],
    };
  },
  methods: {
    clickRemoveImage(image) {
      this.$emit(ActionEnum.ACTION.action, image, ActionEnum.DELETE.action);
    },
  },
};
</script>