import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";
import { pdfDownload } from "@/utils/support";

export const submitOrderReceiptMobile = async (id) => {
    try {
        const response = await axiosInstance.get(
            `${routes.payment}/receipt/${id}/machine`,
            { responseType: "blob" }
        );
        pdfDownload(response, "recibo.pdf");
    } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
    }
};