<template>
  <v-breadcrumbs
    :items="breadcrumbStore.getBreadcrumbTrail()"
    :divider="divider"
  >
    <template v-slot:item="{ item }">
      <router-link :to="item.to">
        <v-breadcrumbs-item :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </router-link>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { useBreadcrumbStore } from "@/components/breadcrumb/usecases/breadcrumbStore";

export default {
  name: "BreadcrumbComponent",
  props: {
    divider: {
      type: String,
      default: ">",
    },
  },
  data() {
    return {};
  },
  setup() {
    const breadcrumbStore = useBreadcrumbStore();
    return {
      breadcrumbStore,
    };
  },
  methods: {},
};
</script>