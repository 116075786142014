import RoleActions from '@/pages/role/enums/RoleActions'

export const roleRouteSearchEnum =
{
  id: 'role',
  path: "/papel/pesquisa/:id",
  name: "role-pesquisa",
  description: "Pesquisa papel",
  order: -1,
  component: () => import("@/pages/role/components/RoleForm.vue"),
  meta: {
    requiresAuth: true,
    requiresroles: [ RoleActions.FIND_ROLE_BY_ID ]
  }
}

