import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";

export const deleteAllById = async (store, items, fields) => {
    if (store.processing) return;

    items.forEach(async item => {
        store.processing = true;
        store.isLoading = true;
        try {
            await axiosInstance.delete(`${routes.parameter}/${item.id}`);
            fields.splice(fields.indexOf(item), 1);
            await store.findPaginate({ page: store.$state.currentPage });
            useSnackBarStore().mostrarSnackBar(
                messageSuccess(`Parametrização deletada com sucesso!`)
            );
            store.closeModal = true;
        } catch (e) {
            useSnackBarStore().mostrarSnackBar(messageError(error(e)));
            store.closeModal = false;
        } finally {
            store.isLoading = false;
            store.processing = false;
        }
    });
};