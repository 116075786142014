export default class EntryMotivatorEnum {
  static VAZIO = { cod: null, description: "" };
  static DISCOUNT = { cod: "DISCOUNT", description: "Desconto" };
  static INSTALLMENT = { cod: "INSTALLMENT", description: "Parcela" };
  static PAYMENT = { cod: "PAYMENT", description: "Pagamento" };
  static INSTALLMENT_ADJUST = {
    cod: "INSTALLMENT_ADJUST",
    description: "Ajuste de parcela",
  };

  static values() {
    return [
      EntryMotivatorEnum.VAZIO,
      EntryMotivatorEnum.DISCOUNT,
      EntryMotivatorEnum.INSTALLMENT,
      EntryMotivatorEnum.PAYMENT,
      EntryMotivatorEnum.INSTALLMENT_ADJUST,
    ];
  }
}
