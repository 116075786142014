import StockActions from '@/pages/stock/enums/stockActions'

export const stockRouteListEnum =
{
  id: 'stock',
  path: "/estoques",
  name: "stock",
  description: "Estoques",
  order: 1,
  component: () => import("@/pages/stock/StockPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ StockActions.FIND_ALL_STOCK ]
  }
}
