import UserActions from '@/pages/user/enums/UserActions'

export const userRouteCreateEnum = {
    id: 'user',
    path: "/usuarios/novo",
    name: "users-novo",
    description: 'Novo usuário',
    order: -1,
    component: () => import("@/pages/user/components/UserForm.vue"),
    meta: {
        requiresAuth: true,
        requiresPermissions: [UserActions.CREATE_CESTA_USER, UserActions.CREATE_USER]
    }
}