import PermissionActions from '@/pages/permission/enums/PermissionActions'

export const permissionRouteListEnum =
{
  id: 'permission',
  path: "/permissoes",
  name: "permission",
  description: "Permissões",
  order: 1,
  component: () => import("@/pages/permission/PermissionPage.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ PermissionActions.FIND_ALL_PERMISSION ]
  }
}
