import StockActions from '@/pages/stock/enums/stockActions'

export const stockRouteSearchEnum =
{
  id: 'stock',
  path: "/estoque/pesquisa/:id",
  name: "stock-pesquisa",
  description: "Pesquisa estoque",
  order: -1,
  component: () => import("@/pages/stock/components/StockForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ StockActions.FIND_STOCK_BY_ID ]
  }
}

