import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";

export const deleteById = async (store, field, fields) => {
    if (store.processing) return;

    store.processing = true;
    store.isLoading = true;
    try {
        await axiosInstance.delete(`${routes.product}/${field.id}`);
        fields.splice(fields.indexOf(field), 1);
        await store.findPaginate({ page: store.$state.currentPage });
        useSnackBarStore().mostrarSnackBar(
            messageSuccess(`Produto desabilitado com sucesso!`)
        );
        store.closeModal = true;
    } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        store.closeModal = false;
    } finally {
        store.isLoading = false;
        store.processing = false;
    }
};