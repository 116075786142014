import { defineStore } from "pinia";

import { useAuthStore } from "@/pages/auth/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { parameterRoutes } from "@/pages/parameter/router/index";
import ParameterActions from "@/pages/parameter/enums/ParameterActions";

import * as createAction from "@/pages/parameter/usecases/createAction.js";
import * as findPaginateAction from "@/pages/parameter/usecases/findPaginateAction.js";
import * as findAllAction from "@/pages/parameter/usecases/findAllAction.js";
import * as findByIdAction from "@/pages/parameter/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/parameter/usecases/updateByIdAction.js";
import * as deleteByIdAction from "@/pages/parameter/usecases/deleteByIdAction.js";
import * as deleteAllByIdAction from "@/pages/parameter/usecases/deleteAllByIdAction.js";

export const useParameterStore = defineStore("parameterStore", {
  state: () => ({
    id: "",
    name: "",
    configValue: "",
    list: [],
    listLoaded: false,
    closeModal: false,
    isLoading: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create(fields) {
      await createAction.create(this, fields);
    },

    async findPaginate(pagination) {
      return await findPaginateAction.findPaginate(this, pagination);
    },

    async findAll() {
      return await findAllAction.findAll(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById(fields) {
      await updateByIdAction.updateById(this, fields.id, fields);
    },

    async deleteById(field, fields) {
      await deleteByIdAction.deleteById(this, field, fields);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    setStore(fields) {
      this.id = fields.id;
      this.name = fields.name;
      this.configValue = fields.configValue;
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(ParameterActions.FIND_PARAMETER_ALL);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    getMenuActions() {
      return [
        new Action(
          ActionEnum.CREATE.name,
          ActionEnum.CREATE.icon,
          ActionEnum.CREATE.action,
          this.hasPermission(ParameterActions.CREATE_PARAMETER),
          new ValidateActionHandler(),
          () =>
            router.push(
              router.getPathFromRouteName(
                parameterRoutes.parameterRouteCreateEnum.name
              ),
              null,
              () => {}
            )
        ),
        new Action(
          ActionEnum.EDIT.name,
          ActionEnum.EDIT.icon,
          ActionEnum.EDIT.action,
          this.hasPermission(ParameterActions.FIND_PARAMETER_ALL),
          new ValidateEditHandler(),
          (items) =>
            router.push(
              router.getPathFromRouteName(
                parameterRoutes.parameterRouteSearchEnum.name
              ) +
                "/" +
                items[0].id,
              null,
              () => {}
            )
        ),
        new Action(
          ActionEnum.DELETE.name,
          ActionEnum.DELETE.icon,
          ActionEnum.DELETE.action,
          this.hasPermission(ParameterActions.DELETE_PARAMETER),
          new ValidateDeleteHandler(),
          (items) => this.deleteAllById(items, this.getList)
        ),
      ];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        new Action(
          ActionEnum.REFRESH.name,
          ActionEnum.REFRESH.icon,
          ActionEnum.REFRESH.action,
          this.hasPermission(ParameterActions.FIND_PARAMETER_ALL),
          new ValidateActionHandler(),
          () => this.findPaginate({ page: this.getCurrentPage })
        ),
        new Action(
          ActionEnum.PAGINATE.name,
          ActionEnum.PAGINATE.icon,
          ActionEnum.PAGINATE.action,
          this.hasPermission(ParameterActions.FIND_PARAMETER_ALL),
          new ValidateActionHandler(),
          (pagination) => this.findPaginate(pagination)
        ),
        new Action(
          ActionEnum.CREATE_SAVE.name,
          ActionEnum.CREATE_SAVE.icon,
          ActionEnum.CREATE_SAVE.action,
          this.hasPermission(ParameterActions.CREATE_PARAMETER),
          new ValidateActionHandler(),
          (items) => this.create(items)
        ),
        new Action(
          ActionEnum.EDIT_SAVE.name,
          ActionEnum.EDIT_SAVE.icon,
          ActionEnum.EDIT_SAVE.action,
          this.hasPermission(ParameterActions.UPDATE_PARAMETER),
          new ValidateActionHandler(),
          (items) => this.updateById(items)
        ),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getCloseModal() {
      return this.$state.closeModal;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findPaginate({ page: this.$state.currentPage });
      }
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
