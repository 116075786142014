import OrderActions from '@/pages/order/enums/OrderActions'

export const orderRouteSearchParametersEnum =
{
  id: 'order',
  path: "/pedido/pesquisa",
  name: "order-search-parameters",
  description: "Pesquisa pedidos com parâmetros",
  order: -1,
  component: () => import("@/pages/order/components/OrderFilter.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ OrderActions.SEARCH_ORDER ]
  }
}

