import { defineStore } from "pinia";

import { useUserStore } from "@/pages/user/usecases/index";
import { userRouteListEnum } from "@/pages/user/router/userRouteList";

import { useParameterStore } from "@/pages/parameter/usecases/index";
import { parameterRouteListEnum } from "@/pages/parameter/router/parameterRouteList";

import { useRoleStore } from "@/pages/role/usecases/index";
import { roleRouteListEnum } from "@/pages/role/router/roleRouteList";

import { useAuthStore } from "@/pages/auth/usecases/index";
import { userRouteSearchEnum } from "@/pages/user/router/userRouteSearch";

import router from "@/router/index";
import UserActions from "@/pages/user/enums/UserActions";

export const usePerfilStore = defineStore("perfilStore", {
  state: () => ({}),
  actions: {
    dadosDoPerfil() {
      return [
        {
          title: "Editar",
          icon: "mdi-pencil",
          to:
            router.getPathFromRouteName(userRouteSearchEnum.name) +
            "/" +
            useAuthStore().getId,
          permission: useUserStore().hasPermission(UserActions.FIND_USER_CESTA_BY_ID) || useUserStore().hasPermission(UserActions.FIND_USER_BY_ID),
        },
        {
          title: "Sair",
          icon: "mdi-logout",
          action: () => useAuthStore().logout(),
          permission: true,
        },
      ];
    },
    configuracoesDoPerfil() {
      return [
        {
          title: "Parametrizações",
          icon: "mdi-code-tags",
          to: parameterRouteListEnum.path,
          permission: useParameterStore().hasPermissionToOpenModule(),
        },
        {
          title: "Permissões",
          icon: "mdi-layers",
          to: roleRouteListEnum.path,
          permission: useRoleStore().hasPermissionToOpenModule(),
        },
        {
          title: "Usuários",
          icon: "mdi-account-multiple",
          to: userRouteListEnum.path,
          permission: useUserStore().hasPermissionToOpenModule(),
        },
      ];
    },
  },
});
