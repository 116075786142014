<template>
  <v-container fluid>
    <v-card>
      <v-toolbar dense flat>
        <v-spacer></v-spacer>
        <Menu
          :name="getMenu().name"
          :permission="getMenu().permission"
          :icon="getMenu().icon"
          :color="getMenu().color"
          :outlined="getMenu().outlined"
          :items="getMenu().items"
          @action="(action) => clickMenuAction(action)"
        />
      </v-toolbar>
      <v-row class="mx-auto w-100 my-2">
        <v-col cols="12" md="12" sm="2">
          <v-text-field
            data-cy="installment-payment-state-field"
            disabled
            :value="formattedPaymentStatus"
            label="Pago"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-text-field
            data-cy="installment-value-field"
            v-model.number="installmentStore.formData.installmentValue"
            type="number"
            label="Valor"
            outlined
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <DateField
            v-model="installmentStore.formData.dueDate"
            label="Data de vencimento"
            :minDate="minDate"
          ></DateField>
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <EntryPage :objectsList="installmentStore.formData.entries" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <div class="mx-1">
            <Button
              :name="'Cancelar'"
              :handleFunction="cancel"
              :permission="true"
            />
          </div>
          <div class="mx-1 mr-4">
            <Button
              :name="'Salvar'"
              :handleFunction="save"
              :permission="actionFinded.permission"
              :outlined="false"
              :color="'primary'"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Button from "@/components/Button.vue";
import EntryPage from "@/pages/entry/EntryPage.vue";
import ActionEnum from "@/domain/action/ActionEnum";
import Menu from "@/components/menu/Menu.vue";
import DateField from "@/components/DateField.vue";
import PaymentStatusEnum from "@/pages/payment/enums/PaymentStatusEnum";

import { todayDateField } from "@/utils/dateUtil";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { useInstallmentStore } from "@/pages/installment/usecases/index";

export default {
  name: "InstallmentForm",
  components: {
    Button,
    EntryPage,
    Menu,
    DateField,
  },
  setup() {
    const installmentStore = useInstallmentStore();
    const dialogStore = useDialogStore();
    return {
      installmentStore,
      dialogStore,
    };
  },
  data() {
    return {
      menu: null,
      isUpdate: useDialogStore().isUpdate,
      actionFinded: {},
      listeners: [],
      minDate: todayDateField,
    };
  },
  async created() {
    this.listeners = this.installmentStore.getActionsListener();
    this.actionFinded = this.listeners.find(
      (a) => a.action === ActionEnum.EDIT_SAVE.action
    );
    const ordersId = useInstallmentStore().formData?.ordersId;
    useInstallmentStore().formData = useDialogStore().data;
    useInstallmentStore().formData.ordersId = ordersId;
  },
  methods: {
    async save() {
      this.actionFinded.execute(this.installmentStore.formData);
      this.cancel();
    },
    cancel() {
      this.dialogStore.closeDialog();
    },
    async clickMenuAction(action) {
      const actionFinded = this.listeners.find((a) => a.action === action);
      actionFinded.execute(useInstallmentStore().formData);
    },
    getMenu() {
      return {
        name: "Ações",
        permission: true,
        icon: "mdi-arrow-collapse-down",
        color: "primary",
        outlined: false,
        items: this.installmentStore.getMenuFormActions(),
      };
    },
  },
  computed: {
    formattedPaymentStatus() {
      return PaymentStatusEnum.formattedPaymentStatus(
        this.installmentStore.formData.paymentStatus
      );
    },
  },
};
</script>
