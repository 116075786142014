import RoleActions from '@/pages/role/enums/RoleActions'

export const roleRouteListEnum =
{
  id: 'role',
  path: "/papeis",
  name: "role",
  description: "Papéis",
  order: 1,
  component: () => import("@/pages/role/RolePage.vue"),
  meta: {
    requiresAuth: true,
    requiresroles: [ RoleActions.FIND_ALL_ROLE ]
  }
}
