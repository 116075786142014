<template>
  <v-container>
    <div v-for="dialog in dialogStore.openDialogs" :key="dialog.id">
      <v-dialog
        v-model="dialog.dialog"
        v-if="dialog.dialog"
        persistent
      >
        <v-card width="100%">
          <v-toolbar color="white">
            <v-toolbar-title class="primary--text">{{
              dialog.title
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <Menu
              :icon="'mdi-dots-vertical'"
              :items="dialog.menu?.items"
            />
            <Button
              :icon="'mdi-close'"
              :handleFunction="() => dialogStore.closeDialog()"
              :permission="true"
            />
          </v-toolbar>
          <component :is="dialog.childComponent"></component>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import Menu from "@/components/menu/Menu.vue";
import Button from "@/components/Button.vue";

import { useDialogStore } from "@/components/dialog/usecases/dialogStore";

export default {
  name: "Dialog",
  components: {
    Menu,
    Button,
  },
  setup() {
    const dialogStore = useDialogStore();
    return { dialogStore };
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
