import { reportRouteCreateEnum } from '@/pages/report/router/reportRouteCreate'
import { reportRouteSearchEnum } from '@/pages/report/router/reportRouteSearch'
import { reportRouteListEnum } from '@/pages/report/router/reportRouteList'


export const reportRoutes = {
    reportRouteCreateEnum,
    reportRouteSearchEnum,
    reportRouteListEnum,
}
