import { defineStore } from "pinia";
import { useAuthStore } from "@/pages/auth/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { workOrderRoutes } from "@/pages/workOrder/router/index";
import WorkOrderActions from "@/pages/workOrder/enums/workOrderActions";

import * as createAction from "@/pages/workOrder/usecases/createAction.js";
import * as findPaginateAction from "@/pages/workOrder/usecases/findPaginateAction.js";
import * as findAllAction from "@/pages/workOrder/usecases/findAllAction.js";
import * as findByIdAction from "@/pages/workOrder/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/workOrder/usecases/updateByIdAction.js";
import * as searchAction from "@/pages/workOrder/usecases/searchAction.js";

export const useWorkOrderStore = defineStore("workOrderStore", {
  state: () => ({
    id: "",
    creationDate: "",
    creator: "",
    observation: "",
    status: "",
    type: "",
    list: [],
    listLoaded: false,
    closeModal: false,
    isLoading: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async create(fields) {
      await createAction.create(this, fields);
    },

    async findPaginate(pagination, force) {
      await findPaginateAction.findPaginate(this, pagination, force);
    },

    async findAll() {
      return await findAllAction.findAll(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById(fields) {
      await updateByIdAction.updateById(this, fields.id, fields);
    },

    async search(fields) {
      await searchAction.search(this, fields);
    },

    refresh() {
      this.findPaginate({ page: this.getCurrentPage }, true);
    },

    setStore(fields) {
      console.log();
      this.id = fields.id;
      this.creationDate = fields.creationDate;
      this.creator = fields.creator;
      this.observation = fields.observation;
      this.status = fields.status;
      this.type = fields.type;
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(WorkOrderActions.FIND_ALL_WORK_ORDER);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    paginateValidation(force, pagination) {
      return !force && pagination.page === this.currentPage && this.listLoaded;
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(WorkOrderActions.CREATE_WORK_ORDER),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              workOrderRoutes.workOrderRouteCreateEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionEdit() {
      return new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(WorkOrderActions.FIND_ALL_WORK_ORDER),
        new ValidateEditHandler(),
        (items) =>
          router.push(
            router.getPathFromRouteName(
              workOrderRoutes.workOrderRouteSearchEnum.name
            ) +
              "/" +
              items[0].id,
            null,
            () => {}
          )
      );
    },

    actionFilter() {
      return new Action(
        ActionEnum.FILTER.name,
        ActionEnum.FILTER.icon,
        ActionEnum.FILTER.action,
        this.hasPermission(WorkOrderActions.SEARCH_WORK_ORDER),
        new ValidateActionHandler(),
        () =>
          router.push(
            router.getPathFromRouteName(
              workOrderRoutes.workOrderRouteSearchParametersEnum.name
            ),
            null,
            () => {}
          )
      );
    },

    actionRefresh() {
      return new Action(
        ActionEnum.REFRESH.name,
        ActionEnum.REFRESH.icon,
        ActionEnum.REFRESH.action,
        this.hasPermission(WorkOrderActions.FIND_ALL_WORK_ORDER),
        new ValidateActionHandler(),
        () => this.refresh()
      );
    },

    actionPaginate() {
      return new Action(
        ActionEnum.PAGINATE.name,
        ActionEnum.PAGINATE.icon,
        ActionEnum.PAGINATE.action,
        this.hasPermission(WorkOrderActions.FIND_ALL_WORK_ORDER),
        new ValidateActionHandler(),
        (pagination) => this.findPaginate(pagination)
      );
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(WorkOrderActions.CREATE_WORK_ORDER),
        new ValidateActionHandler(),
        (items) => this.create(items)
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(WorkOrderActions.UPDATE_WORK_ORDER),
        new ValidateActionHandler(),
        (items) => this.updateById(items)
      );
    },

    actionSearch() {
      return new Action(
        ActionEnum.SEARCH.name,
        ActionEnum.SEARCH.icon,
        ActionEnum.SEARCH.action,
        this.hasPermission(WorkOrderActions.SEARCH_WORK_ORDER),
        new ValidateActionHandler(),
        (items) => this.search(items)
      );
    },

    getMenuActions() {
      return [this.actionCreate(), this.actionEdit(), this.actionFilter()];
    },
    getActionsListener() {
      return [
        ...this.getMenuActions(),
        this.actionRefresh(),
        this.actionPaginate(),
        this.actionCreateSave(),
        this.actionEditSave(),
        this.actionSearch(),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getCloseModal() {
      return this.$state.closeModal;
    },
    getItemsPerPage() {
      return this.$state.itemsPerPage;
    },
    getTotalElements() {
      return this.$state.totalElements;
    },
    getCurrentPage() {
      return this.$state.currentPage;
    },
    getList() {
      if (!this.$state.listLoaded) {
        this.findPaginate({ page: this.$state.currentPage });
      }
      return this.$state.list;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
