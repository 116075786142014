// --- [As rotas devem estar em ordem alfabetica.] ---
// --- [E nenhum rota deve estar no plural.] ---

const api = "/api/v1"

export const routes = {
  address: api + "/address",
  enderecoRural: api + "/endereco_rural",
  rotas: api + "/rotas",
  city: api + "/cities",
  combo: api + "/combos",
  contacts: api + "/contacts",
  customer: api + "/customers",
  dueDateHistory: api + "/due_date_history",
  installment: api + "/installments",
  login: "/login",
  order: api + "/orders",
  painel: api + "/painel",
  parameter: api + "/parameters",
  payment: api + "/payments",
  permission: api + "/permissions",
  picture: api + "/files/up",
  product: api + "/products",
  item: api + "/items",
  region: api + "/regions",
  report: api + "/reports",
  role: api + "/roles",
  stock: api + "/stocks",
  user: api + "/users",
  workorder: api + "/workorders",
};
