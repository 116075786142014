import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";
import router from "@/router/index";

export const updateById = async (store, id, fields) => {
  if (store.processing) return;

  store.processing = true;
  store.isLoading = true;
  store.setStore(fields)
  try {
    await axiosInstance.put(`${routes.workorder}/${id}`, store.$state);
    useSnackBarStore().mostrarSnackBar(
      messageSuccess(`Ordem de serviço atualizada com sucesso!`)
    );
    store.refresh()
    router.back()
    store.closeModal = true;
  } catch (e) {
    useSnackBarStore().mostrarSnackBar(messageError(error(e)));
    store.closeModal = false;
  } finally {
    store.isLoading = false;
    store.processing = false;
  }
};