import Vue from 'vue'
import App from './pages/App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)
Vue.use(PiniaVuePlugin)
const pinia = createPinia()
Vue.use(VueRouter)

Vue.config.productionTip = false

const isProductionSimulated = process.env.VUE_APP_SIMULATE_PROD === 'true'

if (process.env.NODE_ENV === 'production' || isProductionSimulated) {
  Vue.config.silent = true
  Vue.config.devtools = false
  console.warn = () => {}
}

export const vue = new Vue({
  vuetify,
  pinia,
  router,
  render: h => h(App)
}).$mount('#app')
