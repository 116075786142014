import CustomerActions from '@/pages/customer/enums/CustomerActions'

export const customerRouteSearchParametersEnum =
{
  id: 'customer',
  path: "/cliente/pesquisa",
  name: "customer-search-parameters",
  description: "Pesquisa cliente com parâmetros",
  order: -1,
  component: () => import("@/pages/customer/components/CustomerFilter.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ CustomerActions.SEARCH_CUSTOMER ]
  }
}

