import { userRouteCreateEnum } from '@/pages/user/router/userRouteCreate'
import { userRouteSearchEnum } from '@/pages/user/router/userRouteSearch'
import { userRouteListEnum } from '@/pages/user/router/userRouteList'
import { userRouteSearchParametersEnum } from '@/pages/user/router/userRouteSearchParameters'


export const userRoutes = {
    userRouteCreateEnum,
    userRouteSearchEnum,
    userRouteListEnum,
    userRouteSearchParametersEnum,
}