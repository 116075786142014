import { comboRouteCreateEnum } from '@/pages/combo/router/comboRouteCreate'
import { comboRouteSearchEnum } from '@/pages/combo/router/comboRouteSearch'
import { comboRouteListEnum } from '@/pages/combo/router/comboRouteList'
import { comboRouteSearchParametersEnum } from '@/pages/combo/router/comboRouteSearchParameters'


export const comboRoutes = {
    comboRouteCreateEnum,
    comboRouteSearchEnum,
    comboRouteListEnum,
    comboRouteSearchParametersEnum,
}