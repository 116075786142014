import ReportActions from '@/pages/report/enums/ReportActions'

export const reportRouteCreateEnum =
{
  id: 'report',
  path: "/relatorio/novo",
  name: "report-novo",
  description: "Novo relatório",
  order: -1,
  component: () => import("@/pages/report/components/ReportForm.vue"),
  meta: {
    requiresAuth: true,
    requiresPermissions: [ ReportActions.CREATE_REPORT ]
  }
}
