import UserActions from '@/pages/user/enums/UserActions'

export const userRouteListEnum = {
    id: 'user',
    path: "/usuarios",
    name: "users",
    description: 'Usuários',
    order: 1,
    component: () => import("@/pages/user/UserPage.vue"),
    meta: {
        requiresAuth: true,
        requiresPermissions: [UserActions.FIND_USER_ALL, UserActions.FIND_USER_CESTA_ALL]
    }
}
