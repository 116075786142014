export default class NextIdGeneration {
    constructor(list) {
        this.list = list;
    }

    nextId() {
        let nextId = 1;
        if (this.list.length > 0) {
            const maxId = this.list.reduce((max, item) => (item.id > max ? item.id : max), this.list[0].id);
            nextId = maxId + 1;
        }
        return nextId;
    }

}

