import { defineStore } from "pinia";

import { useAuthStore } from "@/pages/auth/usecases/index";
import { useCustomerStore } from "@/pages/customer/usecases/index";
import { usePaymentStore } from "@/pages/payment/usecases/index";
import { useInstallmentStore } from "@/pages/installment/usecases/index";
import { useItemStore } from "@/pages/item/usecases/index";
import { ValidateActionHandler } from "@/domain/validate/ValidateActionHandler";
import { ValidateEditHandler } from "@/domain/validate/ValidateEditHandler";
import { ValidateDeleteHandler } from "@/domain/validate/ValidateDeleteHandler";
import { Action } from "@/domain/action/Action";
import ActionEnum from "@/domain/action/ActionEnum";
import router from "@/router/index";
import { orderRoutes } from "@/pages/order/router/index";
import OrderActions from "@/pages/order/enums/OrderActions";
import OrderForm from "@/pages/order/components/OrderForm.vue";
import { vue } from "@/main";
import ImageForm from "@/pages/order/components/ImageForm.vue";

import * as createAction from "@/pages/order/usecases/createAction.js";
import * as findByIdAction from "@/pages/order/usecases/findByIdAction.js";
import * as updateByIdAction from "@/pages/order/usecases/updateByIdAction.js";
import * as deleteAllByIdAction from "@/pages/order/usecases/deleteAllByIdAction.js";
import * as searchAction from "@/pages/order/usecases/searchAction.js";
import * as submitPdfOrderWebAction from "@/pages/order/usecases/submitPdfOrderWebAction.js";
import * as submitPdfOrderMobileAction from "@/pages/order/usecases/submitPdfOrderMobileAction.js";
import { useDialogStore } from "@/components/dialog/usecases/dialogStore";
import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";
import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageError, error, messageSuccess } from "@/utils/exceptionError";

export const useOrderStore = defineStore("orderStore", {
  state: () => ({
    formData: {
      id: null,
      customerId: null,
      deliveryAddressId: null,
      formPayment: null,
      paymentStatus: null,
      status: null,
      quantityInstallments: null,
      dueDate: null,
      instante: null,
      paymentType: null,
      debtBalance: null,
      totalBalance: null,
      discount: null,
      customer: {},
      deliveryAddress: {},
      deliveryAddresses: [],
      items: [],
      payments: [],
      installments: [],
      files: [],
    },
    list: [],
    listLoaded: false,
    isLoading: false,
    processing: false,
    itemsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    totalElements: 0,
    authorities: [],
  }),
  actions: {
    async carregarImagemPedidoEntregue(file, id) {
      if (this.processing) return;

      this.processing = true;
      try {
        const formDataSubmit = new FormData();
        formDataSubmit.append("file", file);
        formDataSubmit.append("pedidoId", id);

        await axiosInstance.post(
          `${routes.order}/atualiza/rota-entrega-com-imagem`,
          formDataSubmit,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Imagem enviada com sucesso`)
        );
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async atualizarPedidoParaEntregueSemImagem() {
      if (this.processing) return;

      this.processing = true;
      try {
        await axiosInstance.post(
          `${routes.order}/atualiza/${this.formData.id}/rota-entrega-sem-imagem`
        );

        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Pedido entregue com sucesso com sucesso`)
        );
        this.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        this.closeModal = false;
      } finally {
        this.processing = false;
      }
    },

    async create() {
      await createAction.create(this);
    },

    async findById(id) {
      return await findByIdAction.findById(this, id);
    },

    async updateById() {
      await updateByIdAction.updateById(this);
    },

    async deleteAllById(items, fields) {
      await deleteAllByIdAction.deleteAllById(this, items, fields);
    },

    async search(fields) {
      await searchAction.search(this, fields);
    },

    async orderPrintedWeb(orders) {
      await submitPdfOrderWebAction.submitPdfOrderWeb(this, orders.id);
    },

    async orderPrintedMobile(orders) {
      await submitPdfOrderMobileAction.submitPdfOrderMobile(this, orders.id);
    },

    getOrder(fields) {
      return {
        id: fields.id,
        customer: fields.customer,
        status: fields.status,
        deliveryAddress: fields.deliveryAddress,
        deliveryAddresses: fields.customer?.person?.address,
        customerId: fields.customer?.id,
        deliveryAddressId: fields.deliveryAddress?.id,
        items: fields?.items?.map((element) => {
          element.productId = element.product?.id;
          return element;
        }),
        payments: fields.payments,
        formPayment: fields.formPayment,
        quantityInstallments: fields.quantityInstallments,
        dueDate: fields.dueDate,
        installments: fields.installments,
        discount: fields.discount,
        instante: fields.instante,
        files: fields.files,
      };
    },

    setCustomer(customer) {
      return (this.formData.customer = customer);
    },

    addItems(items) {
      return this.formData.items.push(items);
    },

    printerOrder(order) {
      if (vue.$vuetify.breakpoint.xs) {
        this.orderPrintedMobile(order);
      } else {
        this.orderPrintedWeb(order);
      }
    },

    cleanData() {
      this.formData.id = null;
      this.formData.formPayment = null;
      this.formData.paymentStatus = null;
      this.formData.quantityInstallments = null;
      this.formData.dueDate = null;
      this.formData.instante = null;
      this.formData.paymentType = null;
      this.formData.debtBalance = null;
      this.formData.totalBalance = null;
      this.formData.discount = null;
      this.formData.customer = {};
      this.formData.deliveryAddress = {};
      this.formData.deliveryAddresses = [];
      this.formData.items = [];
      this.formData.payments = [];
      this.formData.installments = [];
      this.formData.files = [];
      this.formData.status = null;
      useItemStore().cleanData();
      useItemStore().resetIds();
      useInstallmentStore().cleanData();
      useInstallmentStore().resetIds();
      usePaymentStore().cleanData();
      usePaymentStore().resetIds();
    },

    resetIds() {
      this.formData.customerId = null;
      this.formData.deliveryAddressId = null;
    },

    hasPermissionToOpenModule() {
      return this.getAuthorities.includes(OrderActions.FIND_ORDER_ALL);
    },

    hasPermission(permission) {
      return this.getAuthorities.includes(permission);
    },

    actionCreate() {
      return new Action(
        ActionEnum.CREATE.name,
        ActionEnum.CREATE.icon,
        ActionEnum.CREATE.action,
        this.hasPermission(OrderActions.CREATE_ORDER),
        new ValidateActionHandler(),
        () => {
          router.push(
            router.getPathFromRouteName(orderRoutes.orderRouteCreateEnum.name) +
              "/" +
              this.formData.customer.id,
            null,
            () => {}
          );
          this.cleanData();
        }
      );
    },

    actionEdit() {
      const executable = (items) => {
        let dialogAtivo = useDialogStore().getDialogAtivo;
        if (dialogAtivo?.childComponent) {
          useDialogStore().openDialogWithData({
            title: "Pedido",
            childComponent: OrderForm,
            isUpdate: true,
            data: items[0],
          });
        } else {
          router.push(
            router.getPathFromRouteName(orderRoutes.orderRouteSearchEnum.name) +
              "/" +
              items[0].id,
            null,
            () => {}
          );
        }
      };

      const action = new Action(
        ActionEnum.EDIT.name,
        ActionEnum.EDIT.icon,
        ActionEnum.EDIT.action,
        this.hasPermission(OrderActions.FIND_ORDER_ALL),
        new ValidateEditHandler(),
        executable
      );

      return action;
    },

    actionDelete() {
      return new Action(
        ActionEnum.DISABLE.name,
        ActionEnum.DISABLE.icon,
        ActionEnum.DISABLE.action,
        this.hasPermission(OrderActions.DELETE_ORDER),
        new ValidateDeleteHandler(),
        (items) => {
          this.deleteAllById(items, useCustomerStore().formData.orders);
        }
      );
    },

    actionSubmitPdf() {
      const action = new Action(
        ActionEnum.PRINTER.name,
        ActionEnum.PRINTER.icon,
        ActionEnum.PRINTER.action,
        this.hasPermission(
          OrderActions.SUBMIT_ORDER_PDF_MOBILE ||
            OrderActions.SUBMIT_ORDER_PDF_WEB
        ),
        new ValidateEditHandler("Tem que selecionar um pedido para impressão."),
        (objects) => {
          if (Array.isArray(objects)) {
            objects.forEach((element) => {
              this.printerOrder(element);
            });
          } else {
            this.printerOrder(objects);
          }
        }
      );
      action.setVibisibleInCardList();
      return action;
    },

    actionCreateSave() {
      return new Action(
        ActionEnum.CREATE_SAVE.name,
        ActionEnum.CREATE_SAVE.icon,
        ActionEnum.CREATE_SAVE.action,
        this.hasPermission(OrderActions.CREATE_ORDER),
        new ValidateActionHandler(),
        () => {
          this.create();
        }
      );
    },

    actionEditSave() {
      return new Action(
        ActionEnum.EDIT_SAVE.name,
        ActionEnum.EDIT_SAVE.icon,
        ActionEnum.EDIT_SAVE.action,
        this.hasPermission(OrderActions.UPDATE_ORDER),
        new ValidateActionHandler(),
        (items) => {
          this.updateById(items);
        }
      );
    },

    acaoDeCarregarImagemParaPedidoEntregue() {
      const funcaoParaExecucao = (items) => {
        console.log(items)
        useDialogStore().openDialogWithData({
          title: "Galeria de imagens de pedidos",
          childComponent: ImageForm,
          data: items,
        });
      };
      const acao = new Action(
        "Imagens para comprovação de pedido entregue",
        "mdi-image",
        "carregarImagemParaPedidoEntregue",
        OrderActions.ATUALIZA_PEDIDO_PARA_ENTREGUE_COM_IMAGEM,
        new ValidateEditHandler(
          "Tem que selecionar um pedido para carregar uma imagem."
        ),
        funcaoParaExecucao
      );
      acao.isVisibleInCardList = true;
      return acao;
    },

    acaoDeAlterarPedidoParaEntregue() {
      const funcaoParaExecucao = (items) => {
        if (
          confirm(
            "Você realmente quer atualizar o pedido para entregue sem imagem?"
          )
        ) {
          this.formData.id = items[0]?.id;
          this.atualizarPedidoParaEntregueSemImagem();
        }
      };

      const acao = new Action(
        "Atualizar pedido para entregue sem imagem",
        "mdi-truck",
        "alterarPedidoParaEntregue",
        this.hasPermission(
          OrderActions.ATUALIZA_PEDIDO_PARA_ENTREGUE_SEM_IMAGEM
        ),
        new ValidateEditHandler(
          "Tem que selecionar um pedido para atualizar status de entrega."
        ),
        funcaoParaExecucao
      );
      acao.isVisibleInCardList = true;
      return acao;
    },

    getMenuActions() {
      return [
        this.actionCreate(),
        this.actionEdit(),
        this.actionDelete(),
        this.actionSubmitPdf(),
        this.acaoDeAlterarPedidoParaEntregue(),
        this.acaoDeCarregarImagemParaPedidoEntregue(),
      ];
    },

    getMenuFormActions() {
      return [
        this.actionSubmitPdf(),
        this.acaoDeAlterarPedidoParaEntregue(),
        this.acaoDeCarregarImagemParaPedidoEntregue(),
      ];
    },

    getActionsListener() {
      return [
        ...this.getMenuActions(),
        ...this.getMenuFormActions(),
        this.actionCreateSave(),
        this.actionEditSave(),
      ];
    },
  },
  getters: {
    getIsLoading() {
      return this.$state.isLoading;
    },
    getAuthorities() {
      if (this.$state.authorities)
        this.$state.authorities = useAuthStore().getAuthorities;
      return this.$state.authorities;
    },
  },
});
