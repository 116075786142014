<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <Button
        :name="name"
        :permission="items.filter(item => item.permission).length !== 0"
        :v_bind="attrs"
        :v_on="on"
        :icon="icon"
        :color="color"
        :outlined="outlined"
        :attrs="attrs"
        :on="on"
      />
    </template>

    <v-list>
      <v-list-item-group v-model="selectedItem" color="primary">
        <template v-for="item in items">
          <v-list-item :key="item.title" v-if="item.permission" @click="handleActionClicked(item.action)">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import Button from "@/components/Button.vue";
import ActionEnum from "@/domain/action/ActionEnum";

export default {
  name: "Menu",
  components: {
    Button,
  },
  props: {
    items: {
      type: Array,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    outlined: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedItem: {},
    };
  },
  methods: {
    handleActionClicked(action) {
      this.$emit(ActionEnum.ACTION.action, action);
    },
  },
};
</script>
