import { useSnackBarStore } from "@/components/snackbar/usecases/snackbarStore";
import { messageSuccess, messageError, error } from "@/utils/exceptionError";
import { routes } from "@/router/routes";
import axiosInstance from "@/configs/axios";

export const updateLote = async (store, regions, userId) => {
    if (store.processing) return;

      regions.forEach((region) => region.userId = userId)
      const regionLote = { regions: regions }

      store.processing = true;
      store.isLoading = true;
      try {
        await axiosInstance.put(`${routes.region}/update-lote`, regionLote);
        useSnackBarStore().mostrarSnackBar(
          messageSuccess(`Regiões atualizadas com sucesso!`)
        );
        store.closeModal = true;
      } catch (e) {
        useSnackBarStore().mostrarSnackBar(messageError(error(e)));
        store.closeModal = false;
      } finally {
        store.isLoading = false;
        store.processing = false;
      }
};